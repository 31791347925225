import iziToast from 'izitoast';
import React, { useState } from 'react';
import CONSTANTS from '../../constants/constants';
import userService from '../../service/Users/user.service';

const { FORGOT_PASSWORD_STATUS } = CONSTANTS.CONFIG;

const useItToLoginUser = (): [
  {
    email: string;
    password: string;
    confirmPassword?: string;
    securityCode?: string;
  },
  boolean,
  any,
  (evt: React.ChangeEvent<HTMLInputElement>) => void,
  () => void,
  boolean,
  boolean,
  number,
  () => void,
  (e: React.FormEvent<HTMLFormElement>) => void
] => {
  const initialParams = {
    email: '',
    password: '',
    confirmPassword: '',
    securityCode: ''
  };

  const [formErrors, setFormErrors] = useState({} as any);
  const [isFirstLogin, setLoginAttemptStatus] = useState(false);
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [isVerified, setIsVerified] = useState(true);
  const [isCodeStatus, setIsCodeStatus] = useState(
    FORGOT_PASSWORD_STATUS.LOADER
  );
  const [codeId, setCodeId] = useState(0);
  const [formValues, setFormValues] = useState(initialParams);
  const LoginValidate = (values: any) => {
    let errors = {} as any;

    if (!values.email) {
      errors.email = CONSTANTS.REQUIRED_ERROR.USER.EMAIL;
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
      errors.email = CONSTANTS.ERROR_MESSAGE.USER.EMAIL_VALIDATION;
    }
    if (!values.password) {
      errors.password = CONSTANTS.REQUIRED_ERROR.USER.PASSWORD;
    }
    return errors;
  };

  const setErrorMessageEmpty = (name: string) => {
    if (formErrors[name]) {
      formErrors[name] = '';
    }
  };

  const emailValidation = (values: any) => {
    let errors = {} as any;
    if (!values.email) {
      errors.email = CONSTANTS.REQUIRED_ERROR.USER.EMAIL;
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
      errors.email = CONSTANTS.ERROR_MESSAGE.USER.EMAIL_VALIDATION;
    }
    return errors;
  };

  const securityValidation = (values: any) => {
    let errors = {} as any;
    errors.securityCode = values;
    return errors;
  };

  const passwordFieldsValidation = (values: any) => {
    let errors = {} as any;
    var passwordPattern =
      // eslint-disable-next-line no-useless-escape
      /^(?!.*\s)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹]).{8,}$/m;
    const passwordCheck = passwordPattern.test(values.password);
    if (!values.password) {
      errors.password = CONSTANTS.REQUIRED_ERROR.USER.PASSWORD;
    } else if (!passwordCheck) {
      errors.password = CONSTANTS.ERROR_MESSAGE.USER.PASSWORD_VALIDATION;
    }
    if (!values.confirmPassword) {
      errors.confirmPassword =
        CONSTANTS.REQUIRED_ERROR.FORCE_PASSWORD_RESET.CONFIRM_PASSWORD;
    }
    if (
      values.password.length &&
      values.confirmPassword.length &&
      values.password !== values.confirmPassword
    ) {
      errors.confirmPassword =
        CONSTANTS.ERROR_MESSAGE.FORCE_PASSOWRD_RESET.PASSWORD_NOT_MATCH;
    }
    return errors;
  };

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    let key: string;
    let value = '';
    key = event.target.name;
    if (event.target.name === 'securityCode') {
      value = event.target.value.replace(/[^0-9]/, '');
      verifySecurityCode(value);
    } else {
      value = event.target.value;
    }
    setFormValues({
      ...formValues,
      [key]: value
    });
    setErrorMessageEmpty(key);
  };
  const verifySecurityCode = async (value: string) => {
    if (value.length === CONSTANTS.CONFIG.SECURITY_CODE_LIMIT) {
      const verifyCode = await userService.verfiySecurityCode(value);
      const { status, data } = verifyCode;
      if (status === CONSTANTS.STATUS_CODES.CODE_200) {
        setIsVerified(false);
        setIsCodeStatus(FORGOT_PASSWORD_STATUS.SUCCESS);
        setCodeId(data?.info?.id);
        iziToast.success({
          title: CONSTANTS.TOAST_DEFAULTS.SUCCESS_TITLE,
          message: data?.info?.message
        });
      } else {
        setIsCodeStatus(FORGOT_PASSWORD_STATUS.ERROR);
        setIsVerified(true);
        setFormErrors(
          securityValidation(
            data?.info?.message ? data.info.message : data?.info
          )
        );
      }
    } else {
      setIsVerified(true);
    }
  };

  const handleForgotPassword = async () => {
    formValues.password = '';
    formValues.confirmPassword = '';
    setFormErrors(emailValidation(formValues));
    if (!Object.keys(emailValidation(formValues)).length)
      if (formValues.email) {
        const response = await userService.securityCode(formValues.email);
        const { status, data } = response;
        if (status === CONSTANTS.STATUS_CODES.CODE_200) {
          setIsForgotPassword(true);
          iziToast.success({
            title: CONSTANTS.TOAST_DEFAULTS.SUCCESS_TITLE,
            message: data.info
          });
        } else {
          iziToast.info({
            title: CONSTANTS.TOAST_DEFAULTS.INFO_TITLE,
            message: data.info
          });
        }
      }
  };

  const handleSetForgotPassword = async () => {
    setFormErrors(passwordFieldsValidation(formValues));
    const { email, password } = formValues;
    if (!Object.keys(passwordFieldsValidation(formValues)).length) {
      const updatePassword = await userService.forgotPassword(
        email,
        password,
        codeId
      );
      const { status, data } = updatePassword;
      if (status === CONSTANTS.STATUS_CODES.CODE_200) {
        setIsForgotPassword(false);
        iziToast.success({
          title: CONSTANTS.TOAST_DEFAULTS.SUCCESS_TITLE,
          message: data.info
        });
        setFormValues(initialParams);
      } else {
        iziToast.info({
          title: CONSTANTS.TOAST_DEFAULTS.INFO_TITLE,
          message: data.info
        });
      }
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setFormErrors(LoginValidate(formValues));
    if (!Object.keys(LoginValidate(formValues)).length) {
      const userData = {
        email: formValues.email,
        password: formValues.password
      };
      const loginStatus = await userService.loginUserService(userData);
      const { status, data } = loginStatus;
      if (!(status === CONSTANTS.STATUS_CODES.CODE_200)) {
        iziToast.info({
          title: CONSTANTS.TOAST_DEFAULTS.INFO_TITLE,
          message: data.info
        });
      } else {
        sessionStorage.setItem(
          CONSTANTS.SESSION_STORAGE.AUTH_TOKEN_KEY,
          loginStatus.headers['authorization']
        );
        sessionStorage.setItem(
          CONSTANTS.SESSION_STORAGE.ROLE_KEY,
          data.info.role
        );
        sessionStorage.setItem(
          CONSTANTS.SESSION_STORAGE.USER_ID_KEY,
          data.info.user
        );
        sessionStorage.setItem(
          CONSTANTS.SESSION_STORAGE.NAME_KEY,
          data.info.name
        );
        sessionStorage.setItem(
          CONSTANTS.SESSION_STORAGE.FIRST_LOGIN_STATUS_KEY,
          data.info.isFirstLogin
        );
        setLoginAttemptStatus(data.info.isFirstLogin);
        if (!data.info.isFirstLogin)
          window.location.pathname = CONSTANTS.ROUTE_PATHS.DASHBOARD;
      }
    }
  };

  return [
    formValues,
    isFirstLogin,
    formErrors,
    handleChange,
    handleForgotPassword,
    isForgotPassword,
    isVerified,
    isCodeStatus,
    handleSetForgotPassword,
    handleSubmit
  ];
};

export default useItToLoginUser;
