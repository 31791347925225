import CONSTANTS from '../../../../constants/constants';
import { IFAQData } from '../../../../interface/components.interface';

export const saveFAQ = (
  state: IFAQData[],
  action: { type: string; payload: IFAQData[] }
) => {
  switch (action.type) {
    case CONSTANTS.REDUCER_TYPES.SET_FAQ_DATA:
      return action.payload;
    default:
      return state;
  }
};
