/**
 * All of the reducer functions should be called within the return block.
 */

import intialState from '../intialState';
import { isCourseAdminTableLoading } from './adminPanel/courses/courseAdminTableLoading';
import { isAddCourseButtonClicked } from './adminPanel/courses/isAddCourseButtonClicked';
import { isBulkUploadButtonClicked } from './adminPanel/courses/isBulkUploadButtonClicked';
import { currentCourseSetReducer } from './adminPanel/courses/currentCourseSetReducer';
import { isAddUserButtonClicked } from './adminPanel/users/isAddUserButtonClicked';
import { courseActionMode } from './adminPanel/courses/courseActionMode';
import { currentCourseForSelected } from './adminPanel/courses/currentCourseForSelected';
import { currentCourseData } from './adminPanel/courses/currentCourseData';
import { adminPanelCoursesCountReducer } from './adminPanel/courses/adminPanelCoursesCountReducer';
import { userCreatedAtTheAdminPanel } from './adminPanel/users/userCreatedAtTheAdminPanelReducer';
import { courseCreatedAtTheAdminPanel } from './adminPanel/courses/courseCreatedAtTheAdminPanelReducer';
import { usersTableCurrentDataReducer } from './adminPanel/users/usersTableCurrentDataReducer';
import { adminPanelTotalUsersCountReducer } from './adminPanel/users/adminPanelTotalUsersCountReducer';
import { adminPanelUsersSectionReducer } from './adminPanel/users/adminPanelUsersSection';
import { adminPanelCourseMode } from './adminPanel/users/adminPanelUserMode';
import { adminPanelCurrentSelectedUser } from './adminPanel/users/adminPanelCurrentSelectedUser';
import { IUsersColumnData } from '../../interface/components.interface';
import { getCountriesReducer } from './adminPanel/common/getCountriesReducer';
import { getCertificationReducer } from './adminPanel/common/getCertificationReducer';
import { getInstitutionReducer } from './adminPanel/common/getInstitutionsReducer';
import { getRolesReducer } from './adminPanel/common/getRoleReducer';
import { getEducationalAreaReducer } from './adminPanel/common/getEducationalAreaReducer';
import { userUpdatedOnTheAdminPanel } from './adminPanel/users/userUpdatedOnTheAdminPanel';
import { getOdflFocusReducer } from './adminPanel/common/getOdflFocusReducer';
import { getCategoriesReducer } from './adminPanel/common/getCategoriesReducer';
import { getCourseModesReducer } from './adminPanel/common/getModesReducer';
import { getCourseLevelsReducer } from './adminPanel/common/getLevelsReducer';
import courseList from './siteUser/courseList';
import selectedGlobalValues from './siteUser/selectedGlobalValues';
import { institutionsCount } from './adminPanel/institution/institutionsCount';
import { institutionAddButtonClicked } from './adminPanel/institution/institutionAddButtonClicked';
import { institutionActionMode } from './adminPanel/institution/institutionActionMode';
import { editCurrentInstitution } from './adminPanel/institution/editCurrentInstitution';
import { institutionsData } from './adminPanel/institution/institutionsData';
import { catalogCoursesCount } from './siteUser/useCatalogCourseCount';
import { getCommonInstitutionReducer } from './adminPanel/common/getCommonInstitution';
import { getCommonCountryReducer } from './adminPanel/common/getCommonCountry';
import { setCourseTagsDataReducer } from './adminPanel/common/setCourseTagsDataReducer';
import { saveFAQ } from './adminPanel/common/saveFAQ';
import homeCourseDatas from './siteUser/homeCourseDatas';
import catalogCourseSection from './siteUser/catalogCourseSection';
import { getLoaderStatusReducer } from './adminPanel/common/getLoaderStatusReducer';
import { getAdminLoaderStatusReducer } from './adminPanel/courses/getAdminLoaderStatusReducer';

const reducer = (state = intialState, action: any) => {
  return {
    currentCoursesSet: currentCourseSetReducer(state.currentCoursesSet, action),
    isAddCourseButtonClicked: isAddCourseButtonClicked(
      state.isAddCourseButtonClicked,
      action
    ),
    isAddUserButtonClicked: isAddUserButtonClicked(
      state.isAddUserButtonClicked,
      action
    ),
    isCourseAdminTableLoading: isCourseAdminTableLoading(
      state.isCourseAdminTableLoading,
      action
    ),
    courseActionMode: courseActionMode(state.courseActionMode, action),
    currentCourseForSelected: currentCourseForSelected(
      state.currentSelectedCourseForEdit,
      action
    ),
    currentCourseData: currentCourseData(state.currentCourseData, action),
    adminPanelTotalCourses: adminPanelCoursesCountReducer(
      state.adminPanelTotalCourses,
      action
    ),
    isUserCreatedOnTheAdminPanel: userCreatedAtTheAdminPanel(
      state.isUserCreatedOnTheAdminPanel,
      action
    ),
    isCourseCreatedOnTheAdminPanel: courseCreatedAtTheAdminPanel(
      state.isCourseCreatedOnTheAdminPanel,
      action
    ),
    currentUsersSet: usersTableCurrentDataReducer(
      state.currentUsersSet,
      action
    ),
    adminPanelTotalUsersCount: adminPanelTotalUsersCountReducer(
      state.adminPanelTotalUsersCount,
      action
    ),
    adminPanelUsersSection: adminPanelUsersSectionReducer(
      state.adminPanelUsersSection,
      action
    ),
    adminPanelUserMode: adminPanelCourseMode(state.adminPanelUserMode, action),
    adminPanelCurrentSelectedUser: adminPanelCurrentSelectedUser(
      state.adminPanelCurrentSelectedUser as IUsersColumnData,
      action
    ),
    adminPanelCountries: getCountriesReducer(state.adminPanelCountries, action),
    adminPanelInstitutions: getInstitutionReducer(
      state.adminPanelInstitutions,
      action
    ),
    commonCertification: getCertificationReducer(
      state.commonCertification,
      action
    ),
    commonInstitution: getCommonInstitutionReducer(
      state.commonInstitution,
      action
    ),
    commonCountry: getCommonCountryReducer(state.commonCountry, action),
    adminPanelRoles: getRolesReducer(state.adminPanelRoles, action),
    commonEducationalAreas: getEducationalAreaReducer(
      state.commonEducationalAreas,
      action
    ),
    isUserUpdatedOnTheAdminPanel: userUpdatedOnTheAdminPanel(
      state.isUserUpdatedOnTheAdminPanel,
      action
    ),
    adminPanelOdflFocus: getOdflFocusReducer(state.adminPanelOdflFocus, action),
    adminPanelCategories: getCategoriesReducer(
      state.adminPanelCategories,
      action
    ),
    adminPanelModes: getCourseModesReducer(state.adminPanelModes, action),
    adminPanelLevels: getCourseLevelsReducer(state.adminPanelLevels, action),
    catalogueCourses: courseList(state.catalogueCourses, action),
    globalFilterValues: selectedGlobalValues(state.globalFilterValues, action),
    HomeCourseTitle: homeCourseDatas(state.HomeCourseTitle, action),
    institutionsCount: institutionsCount(state.institutionsCount, action),
    institutionAddButtonClicked: institutionAddButtonClicked(
      state.institutionAddButtonClicked,
      action
    ),
    institutionActionMode: institutionActionMode(
      state.institutionActionMode,
      action
    ),
    editCurrentInstitution: editCurrentInstitution(
      state.editCurrentInstitution,
      action
    ),
    globalLoaderState: getLoaderStatusReducer(state.globalLoaderState, action),
    adminLoaderState: getAdminLoaderStatusReducer(
      state.adminLoaderState,
      action
    ),

    institutionsData: institutionsData(state.institutionsData, action),
    catalogCoursesCount: catalogCoursesCount(state.catalogCoursesCount, action),
    courseTagsData: setCourseTagsDataReducer(state.courseTagsData, action),
    faq: saveFAQ(state.faq, action),
    isBulkUploadButtonInCoursesClicked: isBulkUploadButtonClicked(
      state.isBulkUploadButtonInCoursesClicked,
      action
    ),
    sectionForCatalogCourse: catalogCourseSection(
      state.sectionForCatalogCourse,
      action
    )
  };
};

export default reducer;
