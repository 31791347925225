import CONSTANTS from '../../../../constants/constants';
import { ICourseModeState } from '../../../../interface/components.interface';

export const getCourseModesReducer = (
  state: ICourseModeState[],
  action: { type: string; payload: ICourseModeState[] }
) => {
  switch (action.type) {
    case CONSTANTS.REDUCER_TYPES.SET_MODES_DATA:
      return action.payload;
    default:
      return state;
  }
};
