import { ICourseData } from '../../../../interface/components.interface';
import CONSTANTS from '../../../../constants/constants';

export const currentCourseData = (
  state: any,
  action: { type: string; payload: ICourseData[] }
) => {
  switch (action.type) {
    case CONSTANTS.REDUCER_TYPES.CURRENT_COURSE_DATA:
      return action.payload;
    default:
      return state;
  }
};
