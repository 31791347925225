import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga4';
import { onCLS, onFID, onLCP } from 'web-vitals';
import App from './App';

import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
// require('./component/apm/apm');

// ReactGA.initialize('G-DZ1KETS1PX');
ReactGA.initialize('G-F3H9R1RNFY');

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

const SendAnalytics = () => {
  ReactGA.send({
    hitType: 'pageview',
    page: window.location.pathname
  });
};

onCLS(SendAnalytics);
onFID(SendAnalytics);
onLCP(SendAnalytics);
