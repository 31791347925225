import CONSTANTS from '../../../../constants/constants';

export const adminPanelCoursesCountReducer = (
  state: number,
  action: { type: string; payload: number }
) => {
  switch (action.type) {
    case CONSTANTS.REDUCER_TYPES.ADMIN_PANEL_COURSES_COUNT:
      return action.payload;

    default:
      return state;
  }
};
