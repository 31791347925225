import { useContext, useEffect, useState } from 'react';
import CONSTANTS from '../../constants/constants';
import { AppContext } from '../../context/AppContext';
import {
  IGlobalFilterValues,
  ISelectValue
} from '../../interface/components.interface';
import fetchCategories from '../../service/Common/fetchCategories';
import catalogCourses from '../../service/Courses/catalogCourses';

export default (): [
  any[],
  (e: any) => void,
  (e: any) => void,
  (e: any, key: string) => void,
  () => void
] => {
  const { dispatch, state } = useContext(AppContext);

  const [globalFilterData, setGlobalFilterData] = useState<any[]>();

  useEffect(() => {
    if (state.globalFilterValues.educationalAreaId?.length === 0) {
      setGlobalFilterData([]);

      dispatch({
        type: CONSTANTS.REDUCER_TYPES.SET_GLOBAL_TITLE_VALUES,
        payload: {
          coursesTotal: null,
          selectedTitle: null
        }
      });
    }
  }, [state.globalFilterValues.educationalAreaId]);

  const handleValueChange = (e: any) => {
    dispatch({
      type: CONSTANTS.REDUCER_TYPES.SET_GLOBAL_FILTER_VALUES,
      payload: { [e.target.name]: e.target.value }
    });
  };

  const handleBreadcrumb = async (e: any) => {
    dispatch({
      type: CONSTANTS.REDUCER_TYPES.SET_LOADING_STATUS,
      payload: true
    });
    if (e === CONSTANTS.COURSE_INFO_TAB_PARAMETER.BREADCRUMB_HOME) {
      dispatch({
        type: CONSTANTS.REDUCER_TYPES.SET_GLOBAL_FILTER_VALUES,
        payload: {
          educationalAreaId: [],
          globalSearch: '',
          secondarySearch: '',
          institutionId: [],
          courseModeId: [],
          freeOrPaid: [],
          countryId: [],
          odflFocusId: [],
          cateogryId: [],
          levelId: [],
          certification: []
        }
      });

      const stringifyPayload = JSON.stringify({
        limit: CONSTANTS.PAGINATION.LIMIT,
        section: state.sectionForCatalogCourse
      });
      const listcourses: any = await catalogCourses(stringifyPayload);
      if (listcourses.status === CONSTANTS.STATUS_CODES.CODE_200) {
        dispatch({
          type: CONSTANTS.REDUCER_TYPES.SET_CATEGORIES_DATA,
          payload: []
        });
        dispatch({
          type: CONSTANTS.REDUCER_TYPES.SET_GLOBAL_TITLE_VALUES,
          payload: { globalSearchText: '', defaultOption: true }
        });
        dispatch({
          type: CONSTANTS.REDUCER_TYPES.SET_GLOBAL_TITLE_VALUES,
          payload: {
            coursesTotal: null,
            selectedTitle: e.label
          }
        });
        dispatch({
          type: CONSTANTS.REDUCER_TYPES.SET_CATALOG_COURSE_PAGE,
          payload: CONSTANTS.PAGINATION.INITIAL_PAGE
        });
        dispatch({
          type: CONSTANTS.REDUCER_TYPES.SET_SITE_USER_PAGE_COURSE_LIST,
          payload: listcourses.data.info.catalogCourses
        });
        dispatch({
          type: CONSTANTS.REDUCER_TYPES.CATALOG_COURSES_TOTAL_COUNT,
          payload: listcourses.data.info.total
        });
      }
    } else {
      dispatch({
        type: CONSTANTS.REDUCER_TYPES.SET_GLOBAL_TITLE_VALUES,
        payload: { globalSearchText: '' }
      });
      dispatch({
        type: CONSTANTS.REDUCER_TYPES.SET_GLOBAL_FILTER_VALUES,
        payload: {
          globalSearch: '',
          secondarySearch: ''
        }
      });
      const courses: any = await catalogCourses(
        JSON.stringify({
          educationalAreaId: state.globalFilterValues.educationalAreaId,
          limit: CONSTANTS.PAGINATION.LIMIT,
          section: state.sectionForCatalogCourse
        })
      );
      if (courses.status === CONSTANTS.STATUS_CODES.CODE_200) {
        dispatch({
          type: CONSTANTS.REDUCER_TYPES.SET_GLOBAL_TITLE_VALUES,
          payload: {
            coursesTotal: courses.data.info.total
          }
        });
        dispatch({
          type: CONSTANTS.REDUCER_TYPES.SET_CATALOG_COURSE_PAGE,
          payload: CONSTANTS.PAGINATION.INITIAL_PAGE
        });
        dispatch({
          type: CONSTANTS.REDUCER_TYPES.SET_SITE_USER_PAGE_COURSE_LIST,
          payload: courses.data.info.catalogCourses
        });
        dispatch({
          type: CONSTANTS.REDUCER_TYPES.CATALOG_COURSES_TOTAL_COUNT,
          payload: courses.data.info.total
        });
      }
    }
    dispatch({
      type: CONSTANTS.REDUCER_TYPES.SET_LOADING_STATUS,
      payload: false
    });
  };

  const handleDropDownChange = async (e: any) => {
    dispatch({
      type: CONSTANTS.REDUCER_TYPES.SET_LOADING_STATUS,
      payload: true
    });
    dispatch({
      type: CONSTANTS.REDUCER_TYPES.SET_GLOBAL_FILTER_VALUES,
      payload: { cateogryId: [] }
    });
    setGlobalFilterData([e]);
    let filterPayload: any = {};
    const { globalSearch, secondarySearch } = state.globalFilterValues;

    if (e.value !== 0) {
      filterPayload = { ...filterPayload, educationalAreaId: [e.value] };

      const categories: any = await fetchCategories(e.value);

      if (categories.status === CONSTANTS.STATUS_CODES.CODE_200) {
        const { categories: categoryResultArray } = categories.data.info;
        dispatch({
          type: CONSTANTS.REDUCER_TYPES.SET_CATEGORIES_DATA,
          payload: categoryResultArray
        });
        if (!categoryResultArray.length) {
          dispatch({
            type: CONSTANTS.REDUCER_TYPES.SET_GLOBAL_FILTER_VALUES,
            payload: { cateogryId: [] }
          });
        }
      }
    }

    filterPayload = {
      ...state.globalFilterValues,
      ...filterPayload
    };

    delete filterPayload.cateogryId;
    if (globalSearch) {
      filterPayload = { ...filterPayload, globalSearch };
    }
    if (secondarySearch) {
      filterPayload = { ...filterPayload, secondarySearch };
    }
    if (e.value === 0) {
      dispatch({
        type: CONSTANTS.REDUCER_TYPES.SET_CATEGORIES_DATA,
        payload: []
      });
      dispatch({
        type: CONSTANTS.REDUCER_TYPES.SET_GLOBAL_FILTER_VALUES,
        payload: { educationalAreaId: undefined }
      });
      delete filterPayload.educationalAreaId;
    }
    dispatch({
      type: CONSTANTS.REDUCER_TYPES.SET_GLOBAL_FILTER_VALUES,
      payload: {
        ...filterPayload
      }
    });
    const courses: any = await catalogCourses(
      JSON.stringify({
        ...filterPayload,
        limit: CONSTANTS.PAGINATION.LIMIT,
        section: state.sectionForCatalogCourse
      })
    );
    if (courses.status === CONSTANTS.STATUS_CODES.CODE_200) {
      dispatch({
        type: CONSTANTS.REDUCER_TYPES.SET_GLOBAL_TITLE_VALUES,
        payload: {
          coursesTotal: courses.data.info.total,
          selectedTitle: e.label,
          defaultOption: false
        }
      });
      dispatch({
        type: CONSTANTS.REDUCER_TYPES.SET_CATALOG_COURSE_PAGE,
        payload: CONSTANTS.PAGINATION.INITIAL_PAGE
      });
      dispatch({
        type: CONSTANTS.REDUCER_TYPES.SET_SITE_USER_PAGE_COURSE_LIST,
        payload: courses.data.info.catalogCourses
      });
      dispatch({
        type: CONSTANTS.REDUCER_TYPES.CATALOG_COURSES_TOTAL_COUNT,
        payload: courses.data.info.total
      });
      dispatch({
        type: CONSTANTS.REDUCER_TYPES.SET_LOADING_STATUS,
        payload: false
      });
    }
    dispatch({
      type: CONSTANTS.REDUCER_TYPES.SET_LOADING_STATUS,
      payload: false
    });
  };

  const handleSubmit = async () => {
    dispatch({
      type: CONSTANTS.REDUCER_TYPES.SET_LOADING_STATUS,
      payload: true
    });
    const catalogPayload: IGlobalFilterValues = {
      ...state.globalFilterValues,
      freeOrPaid:
        state.globalFilterValues.freeOrPaid === null ||
        !state.globalFilterValues.freeOrPaid
          ? undefined
          : state.globalFilterValues.freeOrPaid[0],
      certification:
        state.globalFilterValues.certification === null ||
        !state.globalFilterValues.certification
          ? undefined
          : state.globalFilterValues.certification[0]
    };
    if (
      catalogPayload.educationalAreaId &&
      (catalogPayload.educationalAreaId[0] === 0 ||
        !catalogPayload.educationalAreaId.length)
    ) {
      delete catalogPayload.educationalAreaId;
      delete catalogPayload.cateogryId;
    }

    if (!catalogPayload.globalSearch) delete catalogPayload.globalSearch;
    if (!catalogPayload.secondarySearch) delete catalogPayload.secondarySearch;
    if (
      !catalogPayload.institutionId ||
      !catalogPayload.institutionId.length ||
      catalogPayload.institutionId[0] === 0
    )
      delete catalogPayload.institutionId;
    if (
      !catalogPayload.cateogryId ||
      !catalogPayload.cateogryId.length ||
      catalogPayload.cateogryId[0] === 0
    )
      delete catalogPayload.cateogryId;
    if (
      !catalogPayload.courseModeId ||
      !catalogPayload.courseModeId.length ||
      catalogPayload.courseModeId[0] === 0
    )
      delete catalogPayload.courseModeId;
    if (
      !catalogPayload.countryId ||
      !catalogPayload.countryId.length ||
      catalogPayload.countryId[0] === 0
    )
      delete catalogPayload.countryId;
    if (
      !catalogPayload.levelId ||
      !catalogPayload.levelId.length ||
      catalogPayload.levelId[0] === 0
    )
      delete catalogPayload.levelId;
    if (
      !catalogPayload.odflFocusId ||
      !catalogPayload.odflFocusId.length ||
      catalogPayload.odflFocusId[0] === 0
    )
      delete catalogPayload.odflFocusId;
    if (
      catalogPayload.freeOrPaid === undefined ||
      catalogPayload.freeOrPaid === null
    )
      delete catalogPayload.freeOrPaid;
    if (
      catalogPayload.certification === undefined ||
      catalogPayload.certification === null
    )
      delete catalogPayload.certification;

    const courses: any = await catalogCourses(
      JSON.stringify({
        ...catalogPayload,
        limit: CONSTANTS.PAGINATION.LIMIT,
        section: state.sectionForCatalogCourse
      })
    );
    if (courses.status === CONSTANTS.STATUS_CODES.CODE_200) {
      dispatch({
        type: CONSTANTS.REDUCER_TYPES.SET_GLOBAL_TITLE_VALUES,
        payload: {
          globalSearchText: catalogPayload.globalSearch,
          secondarySearchText: catalogPayload.secondarySearch
        }
      });
      dispatch({
        type: CONSTANTS.REDUCER_TYPES.SET_GLOBAL_TITLE_VALUES,
        payload: {
          coursesTotal: courses.data.info.total
        }
      });
      dispatch({
        type: CONSTANTS.REDUCER_TYPES.SET_CATALOG_COURSE_PAGE,
        payload: CONSTANTS.PAGINATION.INITIAL_PAGE
      });

      dispatch({
        type: CONSTANTS.REDUCER_TYPES.SET_SITE_USER_PAGE_COURSE_LIST,
        payload: courses.data.info.catalogCourses
      });
      dispatch({
        type: CONSTANTS.REDUCER_TYPES.CATALOG_COURSES_TOTAL_COUNT,
        payload: courses.data.info.total
      });
    }
    dispatch({
      type: CONSTANTS.REDUCER_TYPES.SET_LOADING_STATUS,
      payload: false
    });
  };

  return [
    globalFilterData as ISelectValue[],
    handleValueChange,
    handleBreadcrumb,
    handleDropDownChange,
    handleSubmit
  ];
};
