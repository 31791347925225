import React from 'react';
import { Col, ListGroup, ListGroupItem, Row } from 'react-bootstrap';
import '../../../style/component/footer.scss';
import CONSTANTS from '../../../constants/constants';

const Footer = () => {
  return (
    <footer className="data-color">
      <div className="container-fluid">
        <Row className="py-2">
          <Col md={12} sm={12} lg={6} className="footer-col-rt long-divider">
            <Row className="justify-content-center">
              <Col sm className="my-3 footer-divider">
                <div className="text-center">
                  <h5 className="heading-style">Supported By</h5>
                  <img
                    className="img-fluid group6"
                    src="/images/MFAT_logo.svg"
                    alt="group6"
                  />
                </div>
              </Col>
              <Col sm className="my-3 footer-divider">
                <div className="text-center">
                  <h5 className="heading-style">Partner</h5>
                  <img
                    className="img-fluid paclogo"
                    src="/images/pacfold_learn_1.svg"
                    alt="paclogo"
                  />
                </div>
              </Col>
              <Col sm className="my-3 CCBYSA">
                <div className="text-center">
                  <a
                    href={CONSTANTS.FOOTER_LOGO_LINK.CCBYSA}
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    <img
                      className="img-fluid ccbysa"
                      src="/images/CCBYSA4.svg"
                      alt="ccbysa"
                    />
                  </a>
                </div>
              </Col>
            </Row>
          </Col>

          <Col md={12} sm={12} lg={6} className="footer-col-lf">
            <Row className=" text-center my-3">
              <h5 className="heading-style" hidden>
                hidden
              </h5>
            </Row>
            <Row className="justify-content-center">
              <Col sm className="mb-3">
                <div className="text-left">
                  <ListGroup>
                    <div className="mb-3">
                      <ListGroupItem className="border-0 listgroupbg">
                        4710 Kingsway, Suite 2500, Burnaby V5H 4M2, British
                        Columbia, Canada
                      </ListGroupItem>
                    </div>
                    <div className="mb-3">
                      <ListGroupItem className="border-0 listgroupbg">
                        WhatsApp No.:&nbsp; + 1 604 210 7180
                      </ListGroupItem>
                      <ListGroupItem className="border-0 listgroupbg">
                        Web:&nbsp;
                        <a
                          href="https://pacificpartnership.col.org"
                          className="text-decoration-none"
                          target="_blank"
                          rel="noreferrer"
                        >
                          pacificpartnership.col.org
                        </a>
                      </ListGroupItem>
                    </div>
                  </ListGroup>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </footer>
  );
};

export default Footer;
