import { IInstitutionMode } from '../../../../interface/reducer.interface';
import CONSTANTS from '../../../../constants/constants';
export const institutionActionMode = (
  state: IInstitutionMode,
  action: { type: string; payload: IInstitutionMode }
) => {
  switch (action.type) {
    case CONSTANTS.REDUCER_TYPES.INSTITUTION_ACTION_MODE:
      return action.payload;
    default:
      return state;
  }
};
