import axios from '../config';
import { Buffer } from 'buffer';

const catalogCourses = async (query?: string): Promise<unknown> => {
  try {
    return await axios({
      url: `/course/catalogCourses?payload=${Buffer.from(
        query as string
      ).toString('base64')}`,
      method: 'GET'
    });
  } catch (err) {
    return err;
  }
};

export default catalogCourses;
