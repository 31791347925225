import { IUsersColumnData } from '../../../../interface/components.interface';
import CONSTANTS from '../../../../constants/constants';

export const adminPanelCurrentSelectedUser = (
  state: IUsersColumnData,
  action: { type: string; payload: IUsersColumnData }
) => {
  switch (action.type) {
    case CONSTANTS.REDUCER_TYPES.SET_CURRENT_SELECTED_USER_DATA:
      return action.payload;

    default:
      return state;
  }
};
