import { ICourseTableColumn } from '../../../../interface/components.interface';
import { ICurrentCoursesReducerAction } from '../../../../interface/reducer.interface';
import CONSTANTS from '../../../../constants/constants';

export const currentCourseSetReducer = (
  state: ICourseTableColumn[],
  action: ICurrentCoursesReducerAction
) => {
  switch (action.type) {
    case CONSTANTS.REDUCER_TYPES.STORE_CURRENT_COURSE_ENTRIES:
      return action.payload;
    default:
      return state;
  }
};
