import axios from '../config';

export default async (categoryId: number): Promise<unknown> => {
  if (categoryId == 0) {
    return null;
  } else {
    try {
      return await axios({
        url: `/cateogry/getAll/${categoryId}`,
        method: 'GET'
      });
    } catch (err) {
      return err;
    }
  }
};
