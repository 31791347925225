import axios from '../config';

export default async (): Promise<unknown> => {
  try {
    return await axios({
      url: '/faq/getAll',
      method: 'GET'
    });
  } catch (err) {
    return (err as any).response;
  }
};
