import React, { createContext, useReducer } from 'react';

import reducer from './reducers/index';
import intialState from './intialState';

interface IAppStoreProvider {
  children: React.ReactChild;
}

const AppContext = createContext<any>(intialState);

const AppStoreProvider: React.FC<IAppStoreProvider> = ({ children }) => {
  const [state, dispatch] = useReducer<any>(reducer, intialState);
  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  );
};

export default AppStoreProvider;
export { AppContext };
