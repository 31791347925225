import CONSTANTS from '../../../../constants/constants';
import { ICategoryState } from '../../../../interface/components.interface';

export const getCategoriesReducer = (
  state: ICategoryState[],
  action: { type: string; payload: ICategoryState[] }
) => {
  switch (action.type) {
    case CONSTANTS.REDUCER_TYPES.SET_CATEGORIES_DATA:
      return action.payload;
    default:
      return state;
  }
};
