import React, { lazy, Suspense } from 'react';
import AppRoutes from './AppRoutes';
import Login from '../page/Login';
import { IRouteConfig } from './routes.interface';
import CONSTANTS from '../constants/constants';
import Loader from '../component/admin/common/Loader';
import CourseDetailed from '../page/CourseDetails';
const HomePage = lazy(() => import('../page/Home'));
const AdminHome = lazy(() => import('../page/admin/Home'));
/**
 * This file is responsible for Configuring Routes for the application.
 * New routes can be added by pusing an object with path and component
 * keys to the config variable/
 *
 */

const Routes = () => {
  const config: IRouteConfig[] = [
    {
      path: CONSTANTS.ROUTE_PATHS.ROOT,
      component: HomePage,
      category: 'siteUser'
    },
    {
      path: CONSTANTS.ROUTE_PATHS.DETAILED,
      component: CourseDetailed,
      category: 'siteUser'
    },
    { path: CONSTANTS.ROUTE_PATHS.LOGIN, component: Login, category: 'admin' },
    {
      path: CONSTANTS.ROUTE_PATHS.DASHBOARD,
      component: AdminHome,
      category: 'admin',
      isLoginRequired: true
    }
  ];

  return (
    <Suspense fallback={<Loader />}>
      <AppRoutes routeConfig={config} />
    </Suspense>
  );
};

export default Routes;
