/* eslint-disable no-case-declarations */
import { useContext, useEffect } from 'react';
import CONSTANTS from '../../constants/constants';
import { AppContext } from '../../context/AppContext';

import { IGlobalFilterValues } from '../../interface/components.interface';
import catalogCourses from '../../service/Courses/catalogCourses';

export default (): [() => void] => {
  const { dispatch, state } = useContext(AppContext);

  useEffect(() => {
    if (state.sectionForCatalogCourse !== 1) handleSiteUserCourses();
  }, [state.sectionForCatalogCourse]);

  useEffect(() => {
    handleSiteUserCourses();
  }, []);

  const handleSiteUserCourses = async () => {
    dispatch({
      type: CONSTANTS.REDUCER_TYPES.SET_LOADING_STATUS,
      payload: true
    });
    const catalogPayload: IGlobalFilterValues = {
      ...state.globalFilterValues,
      freeOrPaid:
        state.globalFilterValues.freeOrPaid === null ||
        !state.globalFilterValues.freeOrPaid
          ? undefined
          : state.globalFilterValues.freeOrPaid[0],
      certification:
        state.globalFilterValues.certification === null ||
        !state.globalFilterValues.certification
          ? undefined
          : state.globalFilterValues.certification[0]
    };

    if (
      catalogPayload.educationalAreaId &&
      (catalogPayload.educationalAreaId[0] === 0 ||
        !catalogPayload.educationalAreaId.length)
    ) {
      delete catalogPayload.educationalAreaId;
      delete catalogPayload.cateogryId;
    }

    if (!catalogPayload.globalSearch) delete catalogPayload.globalSearch;
    if (!catalogPayload.secondarySearch) delete catalogPayload.secondarySearch;
    if (!catalogPayload.institutionId || !catalogPayload.institutionId.length)
      delete catalogPayload.institutionId;
    if (!catalogPayload.courseModeId || !catalogPayload.courseModeId.length)
      delete catalogPayload.courseModeId;
    if (!catalogPayload.countryId || !catalogPayload.countryId.length)
      delete catalogPayload.countryId;
    if (!catalogPayload.levelId || !catalogPayload.levelId.length)
      delete catalogPayload.levelId;
    if (!catalogPayload.odflFocusId || !catalogPayload.odflFocusId.length)
      delete catalogPayload.odflFocusId;
    if (
      catalogPayload.freeOrPaid === undefined ||
      catalogPayload.freeOrPaid === null
    )
      delete catalogPayload.freeOrPaid;
    if (
      catalogPayload.certification === undefined ||
      catalogPayload.certification === null
    )
      delete catalogPayload.certification;

    const courses: any = await catalogCourses(
      JSON.stringify({
        ...catalogPayload,
        limit: CONSTANTS.PAGINATION.LIMIT,
        section: state.sectionForCatalogCourse
      })
    );
    if (courses.status === CONSTANTS.STATUS_CODES.CODE_200) {
      dispatch({
        type: CONSTANTS.REDUCER_TYPES.SET_GLOBAL_TITLE_VALUES,
        payload: {
          globalSearchText: catalogPayload.globalSearch,
          secondarySearchText: catalogPayload.secondarySearch
        }
      });
      dispatch({
        type: CONSTANTS.REDUCER_TYPES.SET_SITE_USER_PAGE_COURSE_LIST,
        payload: courses.data.info.catalogCourses
      });
      dispatch({
        type: CONSTANTS.REDUCER_TYPES.CATALOG_COURSES_TOTAL_COUNT,
        payload: courses.data.info.total
      });
    }
    dispatch({
      type: CONSTANTS.REDUCER_TYPES.SET_LOADING_STATUS,
      payload: false
    });
  };

  return [handleSiteUserCourses];
};
