import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'izitoast/dist/css/iziToast.min.css';
import Routes from './route/Routes';
import AppStoreProvider from './context/AppContext';
import iziToast, { IziToastPosition } from 'izitoast';
import CONSTANTS from './constants/constants';

function App() {
  return (
    <React.Fragment>
      {iziToast.settings({
        position: CONSTANTS.TOAST_DEFAULTS.POSITION as IziToastPosition,
        timeout: CONSTANTS.TOAST_DEFAULTS.TIMEOUT,
        displayMode: CONSTANTS.TOAST_DEFAULTS.DISPLAY_MODE
      })}
      <AppStoreProvider>
        <Routes />
      </AppStoreProvider>
    </React.Fragment>
  );
}

export default App;
