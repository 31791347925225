import CONSTANTS from '../../../../constants/constants';
import { IRoleState } from '../../../../interface/components.interface';

export const getRolesReducer = (
  state: IRoleState[],
  action: { type: string; payload: IRoleState[] }
) => {
  switch (action.type) {
    case CONSTANTS.REDUCER_TYPES.SET_ROLE_DATA:
      return action.payload;

    default:
      return state;
  }
};
