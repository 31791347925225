import CONSTANTS from '../../../../constants/constants';
import { IOdflFocusState } from '../../../../interface/components.interface';

export const getOdflFocusReducer = (
  state: IOdflFocusState[],
  action: { type: string; payload: IOdflFocusState[] }
) => {
  switch (action.type) {
    case CONSTANTS.REDUCER_TYPES.SET_ODFL_FOCUS_DATA:
      return action.payload;

    default:
      return state;
  }
};
