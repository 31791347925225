import axios from '../config';

export default async (CourseId: any): Promise<unknown> => {
  try {
    return await axios({
      url: `/course/${CourseId}`,
      method: 'GET'
    });
  } catch (err) {
    return err;
  }
};
