import CONSTANTS from '../../../../constants/constants';

export const isBulkUploadButtonClicked = (
  state: boolean,
  action: { type: string; payload: boolean }
) => {
  switch (action.type) {
    case CONSTANTS.REDUCER_TYPES.BULK_UPLOAD_CLICKED:
      return action.payload;

    default:
      return state;
  }
};
