import CONSTANTS from '../constants/constants';
import {
  ICourseTableColumn,
  ICourseData,
  IUsersColumnData,
  ICountryState,
  IInstitutionState,
  IRoleState,
  IEducationalAreaState,
  IOdflFocusState,
  ICategoryState,
  ICourseModeState,
  ICourseLevelState,
  IGlobalFilterValues,
  ICommonInstitution,
  ICommonCountry,
  ICourseTags,
  IFAQData,
  IHomeCourseTitle,
  ICommonCertification
} from '../interface/components.interface';
import {
  ICourseMode,
  IInstitutionColumnData,
  IInstitutionMode,
  IUserMode
} from '../interface/reducer.interface';

// Global Intial States Should be defined here
const currentCoursesSet: ICourseTableColumn | [] = [];
const currentUsersSet: IUsersColumnData | [] = [];

const isCourseUpdated: boolean = false;
const isCourseAdminTableLoading: boolean = true;
const isAddCourseButtonClicked: boolean = false;
const isCourseLoaderOn: boolean = false;
const isBulkUploadButtonInCoursesClicked: boolean = false;
const isAddUserButtonClicked: boolean = false;
const courseActionMode: ICourseMode = 'create';
const currentSelectedCourseForEdit: any = {};
const currentCourseData: ICourseData[] = [];
const adminPanelTotalCourses: number = 0;
const isUserCreatedOnTheAdminPanel: boolean = false;
const isCourseCreatedOnTheAdminPanel: boolean = false;
const adminPanelTotalUsersCount: number = 0;
const adminPanelUsersSection: number = CONSTANTS.PAGINATION.INITIAL_PAGE;
const adminPanelUserMode: IUserMode = 'create';
const adminPanelCurrentSelectedUser: IUsersColumnData | {} = {};
const isUserUpdatedOnTheAdminPanel: boolean = false;
const adminLoaderState: boolean = false;

// Institutions
const institutionsCount: number = 0;
const institutionAddButtonClicked: boolean = false;
const institutionActionMode: IInstitutionMode = 'create';
const institutionsData: IInstitutionColumnData[] = [];
const editCurrentInstitution: any = {};
// Common States
const adminPanelCountries: ICountryState | [] = [];
const adminPanelInstitutions: IInstitutionState[] | [] = [];
const adminPanelRoles: IRoleState[] | [] = [];
const commonEducationalAreas: IEducationalAreaState[] | [] = [];
const commonCountry: ICommonCountry[] | [] = [];
const commonInstitution: ICommonInstitution[] | [] = [];
const adminPanelOdflFocus: IOdflFocusState[] | [] = [];
const adminPanelCategories: ICategoryState[] | [] = [];
const adminPanelModes: ICourseModeState[] | [] = [];
const adminPanelLevels: ICourseLevelState[] | [] = [];
const commonCertification: ICommonCertification[] | [] = [];
const catalogueCourses: any = [];
const courseTagsData: ICourseTags | [] = [];
const globalFilterValues: IGlobalFilterValues | {} = {}; // Includes Global Dropdown and Search
const globalLoaderState: boolean = false;

const catalogCoursesCount: number = 0;
const faq: IFAQData[] = [];
const sectionForCatalogCourse = CONSTANTS.PAGINATION.INITIAL_PAGE;

const HomeCourseTitle: IHomeCourseTitle = {
  coursesTotal: null,
  selectedTitle: '',
  globalSearch: '',
  secondarySearchText: '',
  defaultOption: undefined
};
export default {
  currentCoursesSet,
  isCourseUpdated,
  isCourseAdminTableLoading,
  isAddCourseButtonClicked,
  isCourseLoaderOn,
  isAddUserButtonClicked,
  courseActionMode,
  currentSelectedCourseForEdit,
  currentCourseData,
  adminPanelTotalCourses,
  isUserCreatedOnTheAdminPanel,
  isBulkUploadButtonInCoursesClicked,
  isUserUpdatedOnTheAdminPanel,
  isCourseCreatedOnTheAdminPanel,
  currentUsersSet,
  adminPanelTotalUsersCount,
  adminPanelUsersSection,
  adminPanelUserMode,
  adminPanelCurrentSelectedUser,
  adminPanelCountries,
  adminPanelInstitutions,
  adminPanelRoles,
  commonEducationalAreas,
  adminPanelOdflFocus,
  adminPanelCategories,
  adminPanelModes,
  adminPanelLevels,
  catalogueCourses,
  globalFilterValues,
  institutionsCount,
  institutionAddButtonClicked,
  institutionActionMode,
  institutionsData,
  editCurrentInstitution,
  commonInstitution,
  commonCountry,
  courseTagsData,
  faq,
  HomeCourseTitle,
  sectionForCatalogCourse,
  catalogCoursesCount,
  commonCertification,
  globalLoaderState,
  adminLoaderState
};
