import axios from 'axios';
import CONSTANTS from '../constants/constants';

declare const process: {
  env: {
    REACT_APP_ENV: string;
  };
};

function getAppBaseUrl(env: string) {
  switch (env) {
    case 'dev':
      return 'http://localhost:5000/api/v1';

    case 'staging':
      return 'https://col-stg-alb.col.crystaldelta.net/api/v1';

    case 'uat':
      return 'https://uat-alb.odlcatalogue4pacific.org/api/v1';

    case 'production':
      return 'https://www.odlcatalogue4pacific.org/lb/api/v1';
      // return 'https://prod-alb.odlcatalogue4pacific.org/api/v1';
  }
}

export default axios.create({
  baseURL: getAppBaseUrl(process.env.REACT_APP_ENV),
  withCredentials: true,
  headers: {
    authorization: sessionStorage.getItem(
      CONSTANTS.SESSION_STORAGE.AUTH_TOKEN_KEY
    ) as string
  }
});
