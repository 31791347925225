import CONSTANTS from '../../../../constants/constants';
import { ICommonCountry } from '../../../../interface/components.interface';

export const getCommonCountryReducer = (
  state: ICommonCountry[],
  action: { type: string; payload: ICommonCountry[] }
) => {
  switch (action.type) {
    case CONSTANTS.REDUCER_TYPES.SET_COMMON_COUNTRY_DATA:
      return action.payload;
    default:
      return state;
  }
};
