import React, { useState } from 'react';
import {
  IPasswords,
  IResetPasswordErrorMessages
} from '../../interface/components.interface';
import CONSTANTS from '../../constants/constants';
import iziToast from 'izitoast';
import userService from '../../service/Users/user.service';
import { AxiosResponse } from 'axios';

const useItToResetIntialPassword = (): [
  IPasswords,
  IResetPasswordErrorMessages,
  (e: React.ChangeEvent<HTMLInputElement>) => void,
  (
    e: React.FormEvent<HTMLFormElement>,
    old: string,
    mail: string
  ) => Promise<void>
] => {
  const [passwords, setPasswords] = useState<IPasswords>({
    confirmPassword: '',
    newPassword: ''
  });

  const [errors, setErrors] = useState<IResetPasswordErrorMessages>({});

  const validatePasswordsFields = (oldPassword: string) => {
    const errors: IResetPasswordErrorMessages = {};
    if (!passwords.newPassword) {
      errors.newPassword =
        CONSTANTS.REQUIRED_ERROR.FORCE_PASSWORD_RESET.NEW_PASSWORD;
    } else {
      var reg =
        // eslint-disable-next-line no-useless-escape
        /^(?!.*\s)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹]).{8,}$/m;
      const passwordCheck = reg.test(passwords.newPassword);
      if (!passwordCheck) {
        errors.newPassword = CONSTANTS.ERROR_MESSAGE.USER.PASSWORD_VALIDATION;
      }
    }
    if (!passwords.confirmPassword) {
      errors.confirmPassword =
        CONSTANTS.REQUIRED_ERROR.FORCE_PASSWORD_RESET.CONFIRM_PASSWORD;
    }

    if (!oldPassword) {
      errors.oldPassword =
        CONSTANTS.REQUIRED_ERROR.FORCE_PASSWORD_RESET.OLD_PASSWORD;
    }

    if (passwords.newPassword !== passwords.confirmPassword) {
      errors.confirmPassword =
        CONSTANTS.ERROR_MESSAGE.FORCE_PASSOWRD_RESET.PASSWORD_NOT_MATCH;
    }
    return errors;
  };

  const handlePasswordsChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPasswords((prev) => ({
      ...prev,
      [event.target.name]: event.target.value
    }));
  };

  const resetPassword = async (
    e: React.FormEvent<HTMLFormElement>,
    oldPassword: string,
    email: string
  ) => {
    e.preventDefault();
    const errorMessages = validatePasswordsFields(oldPassword);
    setErrors(errorMessages);
    if (!Object.keys(errorMessages).length) {
      if (passwords.newPassword === oldPassword) {
        iziToast.info({
          title: CONSTANTS.TOAST_DEFAULTS.INFO_TITLE,
          message: CONSTANTS.ERROR_MESSAGE.FORCE_PASSOWRD_RESET.OLD_NEW_SAME
        });
      } else {
        const resetStatus = await userService.resetIntialLoginPassword(
          email,
          oldPassword,
          passwords.newPassword
        );
        if (
          (resetStatus as AxiosResponse).status ===
          CONSTANTS.STATUS_CODES.CODE_200
        ) {
          sessionStorage.setItem(
            CONSTANTS.SESSION_STORAGE.FIRST_LOGIN_STATUS_KEY,
            (resetStatus as AxiosResponse).data.info.isFirstLogin
          );
          if (!(resetStatus as AxiosResponse).data.info.isFirstLogin)
            window.location.pathname = CONSTANTS.ROUTE_PATHS.DASHBOARD;
        } else {
          iziToast.info({
            title: CONSTANTS.TOAST_DEFAULTS.INFO_TITLE,
            message: (resetStatus as AxiosResponse).data.info
          });
        }
      }
    }
  };

  return [passwords, errors, handlePasswordsChange, resetPassword];
};

export default useItToResetIntialPassword;
