import CONSTANTS from '../../../../constants/constants';

export const isCourseAdminTableLoading = (
  state: boolean,
  action: { type: string; payload: boolean }
) => {
  switch (action.type) {
    case CONSTANTS.REDUCER_TYPES.ADMIN_COURSE_TABLE_LOADING:
      return action.payload;

    default:
      return state;
  }
};
