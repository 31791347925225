/* eslint-disable quotes */
/* eslint-disable prettier/prettier */
export default {
  CATALOG: {
    RECENT_TITLES: ' Recently Added Courses',
    RECENT_TITLE: ' Recently Added Course',
    RECENT_TITLE_NOT_FOUND: ' No Records Found',
    VIEW_MORE_BTN: 'View More',
    INTEREST_BTN: 'I am interested',
    CERTIFICATE_AVAILABLE_NONCREDIT_ELIGIBLE:
      'Certificate Available (Non-credit eligible)',
    CERTIFICATE_NOT_AVAILABLE: 'Certificate Not Available',
    CERTIFICATE_AVAILABLE_CREDIT_ELIGIBLE:
      'Certificate Available (credit eligible)',
    FAQ: 'FAQ'
  },

  ADMIN_PANEL: {
    COURSE: {
      ADD_COURSE: 'Add Course',
      UPDATE_COURSE: 'Update Course'
    },
    INSTITUTION: {
      ADD_INSTITUTION: 'Add Institution',
      UPDATE_INSTITUTION: 'Update Institution'
    },
    USER: {
      ADD_USER: 'Add User',
      UPDATE_USER: 'Update User'
    }
  },

  CONFIG: {
    SHORT_DESC_LIMIT: 3000,
    LONG_DESC_LIMIT: 6000,
    EXPECTED_TYPES: ['image/jpeg', 'image/png'],
    MAX_FILE_SIZE: 1048576, // 1 MB
    CAROUSEL_INTERVAL: 8000,
    DEFAULT_IMAGE_PATH: '/images/blue.jpg',
    IS_VALID_EMAIL: /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g,
    DURATION_DROPDOWN: [
      { value: 1, label: 'Days' },
      { value: 2, label: 'Weeks' },
      { value: 3, label: 'Months' }
    ],
    EDIT_ICON: '/images/Edit.svg',
    DELETE_ICON: '/images/Delete.svg',
    SECURITY_CODE_LIMIT: 6,
    FORGOT_PASSWORD_STATUS: {
      LOADER: 0,
      SUCCESS: 1,
      ERROR: 2
    },
    HELP_DOCUMENT_PATH: '/help_document/help_document.pdf'
  },
  FAQ_ACTIONS: {
    TITLE: { create: 'Add FAQ', update: 'Update FAQ', delete: 'Delete FAQ' },
    BUTTON_TEXT: { create: 'Add', update: 'Update', delete: 'Delete' }
  },
  BULK_COURSE_UPLOAD: {
    MAX_SIZE: 10485760,
    FILE_EXT: 'text/csv'
  },
  FIELD_LIMIT: {
    COURSE: {
      TITLE: 300,
      CODE: 50,
      URL: 300,
      THUMBNAIL_URL: 500,
      SHORT_DESCRIPTION: 3000,
      LONG_DESCRIPTION: 6000,
      DURATION_VALUE: 3,
      WEEKLY_WORKLOAD: 3,
      AMOUNT: 8,
      FACILITATOR_NAME: 120,
      FACILITATOR_MAIL: 120
    },
    USER: {
      NAME: 120,
      EMAIL: 120,
      PASSWORD: 200,
      SECURITY_CODE: 6
    },
    INSTITUTION: {
      CAMPUS: 120,
      WEBSITE: 120
    },
    COMMON_ADMIN_FIELD: 120,
    QUESTION_FIELD: 600,
    ANSWER_FIELD: 6000
  },
  FILTER_DROPDOWN_NAMES: {
    CATEGORY: 'Category',
    INSTITUTION: 'Institution',
    ODFLFOCUS: 'odflFocus',
    COUNTRY: 'Country',
    COURSEMODE: 'CourseMode',
    FREE_PAID: 'Free_Paid',
    COURSE_LEVEL: 'CourseLevel',
    CERTIFICATION: 'certification'
  },
  DROPDOWN_TYPE: {
    SINGLE_SELECT: 'single',
    MULTI_SELECT: 'multi'
  },
  FILTER_OPTIONS: {
    Institution: 'commonInstitution',
    Category: 'adminPanelCategories',
    odflFocus: 'adminPanelOdflFocus',
    Country: 'commonCountry',
    CourseMode: 'adminPanelModes',
    CourseLevel: 'adminPanelLevels'
  },
  REQUIRED_ERROR: {
    COURSE: {
      TITLE: 'Title is required',
      EDCUATION_AREA_ID: 'Educational area is required',
      URL: 'Course URL is required',
      SHORT_DESCRIPTION: 'Short description is required',
      LONG_DESCRIPTION: 'Long description is required',
      REGISTRANTS_OPEN: 'Registrants open to is required',
      START_DATE: 'Course start date is required',
      FULLY_ONLINE: 'Course Fully online is required',
      DURATION_VALUE: 'Duration is required',
      IS_PAID: 'Course Free or Paid is required',
      DURATION: 'Duration type is required',
      LEVEL_ID: 'Course level is required',
      PAID_COST: 'Course cost is required',
      SCHEDULE: 'Course schedule is required',
      CERTIFICATION: 'Certification is required',
      COUNTRY_ID: 'Country is required',
      THUMBNAIL_OR_DEFAULT_IMAGE: 'Course image or default image is required'
    },
    USER: {
      NAME: 'Name is required',
      EMAIL: 'Email is required',
      PASSWORD: 'Password is required',
      ROLETYPE: 'User role is required',
      COUNTRY: 'Country is required',
      INSTITUTION: 'Institution is required',
      EMAIL_CONFIRMATION: `Email does not match`
    },
    FORCE_PASSWORD_RESET: {
      NEW_PASSWORD: 'New Password is required',
      OLD_PASSWORD: 'Old Password is required',
      CONFIRM_PASSWORD: 'Confirm Password is required'
    },
    INSTITUTION: {
      CAMPUS: 'Institution is required',
      COUNTRY_ID: 'Country is required'
    }
  },
  ERROR_MESSAGE: {
    FORCE_PASSOWRD_RESET: {
      PASSWORD_NOT_MATCH: 'Password does not match',
      OLD_NEW_SAME: 'Old password cannot be your new password'
    },
    COURSE: {
      SHORT_DESCRIPTION:
        'Short description should be less than 3000 characters',
      LONG_DESCRIPTION: 'Long description should be less than 6000 characters',
      FILE_SIZE_LIMIT: 'File exceeds limit',
      INVALID_FILE_TYPE: 'Invalid file type',
      INVALID_URL: 'Invalid URL'
    },
    USER: {
      EMAIL_VALIDATION: 'Please enter valid email address',
      PASSWORD_VALIDATION:
        'Password Must be more than 8 characters and contain an uppercase, lowercase letter with a number and special character'
    },
    INSTITUTION: {
      WEBSITE: 'Please enter a valid URL',
      INSTITUTION_LINKED_WITH_COURSE_OR_USER:
        'Institution associated with more than one course/user'
    },
    COMMON: {
      NOT_FOUND: 'Page Not Found',
      UNAUTHORIZED: 'Unauthorized'
    },
    BULK_COURSE_UPLOAD: {
      MAX_SIZE: 'Exceeded Max Size (max size: 10MB)',
      FORMAT: 'Unsupported file format (supported format: CSV)'
    }
  },
  SUCCESS_MESSAGES: {
    ADMIN_COURSE_TABLE_REFRESH: 'Courses Refreshed',
    DELETE_ADMIN_COURSE_TABLE_ENTRY: 'Course deleted successfully',
    PUBLISH_UNPUBLISH_MESSAGE: 'Course',
    COURSE_CREATE: 'Course created successfully',
    COURSE_UPDATE: 'Course updated successfully',
    CATALOG_COURSE: 'Catalog courses loaded successfully',
    USER_CREATE: 'User created successfully',
    USER_UPDATE: 'User details updated successfully',
    INSTITUTION_CREATE: 'Institution is successfully added',
    INSTITUTION_UPDATE: 'Institution updated successfully',
    INSTITUTION_REMOVE: 'Institution deleted successfully'
  },
  STATUS_CODES: {
    CODE_200: 200,
    CODE_201: 201,
    CODE_500: 500,
    CODE_204: 204,
    CODE_404: 404,
    CODE_401: 401,
    CODE_409: 409,
    CODE_202: 202
  },
  TOAST_DEFAULTS: {
    TIMEOUT: 6000,
    POSITION: 'topRight',
    DISPLAY_MODE: 1,
    INFO_TITLE: 'Info',
    SUCCESS_TITLE: 'Success',
    MESSAGES: {
      ERROR: {
        USER_UPDATE: 'Unable to update user',
        USER_CREATE: 'Unable to create user',
        NO_CHANGES: 'No changes performed',
        USER_GET_ALL: 'Unable to get users',
        USER_DELETE: 'Unable to delete user',
        NOT_ALLOWED: 'Operation Not Permitted',
        CONFLICT: 'User already exists',
        INSTITUTION_CONFLICT: 'Institution already exists',
        EDUCATIONAL_AREA_NOT_SELECTED: 'Please select an educational area',
        BULK_UPLOAD: 'Unable to upload file',
        BULK_UPLOAD_HISTORY: 'Unable to get upload history'
      },
      SUCCESS: {
        USER_DELETE: 'User deleted successfully',
        BULK_UPLOAD: 'File uploaded successfully'
      }
    }
  },
  SESSION_STORAGE: {
    ROLE_KEY: 'currentUserRole',
    USER_ID_KEY: 'currentUserId',
    NAME_KEY: 'currentUserName',
    AUTH_TOKEN_KEY: 'currentUserAuthToken',
    FIRST_LOGIN_STATUS_KEY: 'isFirstLogin'
  },
  ROUTE_PATHS: {
    DESCRIPTION: '/description',
    DASHBOARD: '/admin/dashboard',
    LOGIN: '/admin/login',
    ROOT: '/',
    DETAILED: ':id/course_details',
    HOME_NAVIGATE: '/course_details'
  },
  USER_ROLE_ID: {
    COL_ADMIN: 1,
    INSTITUTIONAL_ADMIN: 2,
    NATIONAL_ADMIN: 3
  },
  FOOTER_LOGO_LINK: {
    CCBYSA: 'https://creativecommons.org/licenses/by/4.0/'
  },
  PAGINATION: {
    LIMIT: 10,
    INITIAL_PAGE: 1
  },
  SCROLL_TOP: {
    TOP: 0
  },
  COMMON_ADMIN_FIELD_ADD_NEW_VALUES_INDEX: {
    EDUCATIONAL_AREA: 0,
    CATEGORY: 1,
    ODFL_FOCUS: 2,
    COUNTRY: 3,
    COURSE_MODE: 4,
    COURSE_TAGS: 5
  },
  COMMON_ADMIN_FIELD_MODEL_TITLE: [
    'Add Educational Area',
    'Add Category',
    'Add ODFL Focus',
    'Add Country',
    'Add Course Mode',
    'Add Associated Keyword'
  ],
  COMMON_ADMIN_FIELD_MODEL_UPDATE_TITLE: [
    'Update Educational Area',
    'Update Category',
    'Update ODFL Focus',
    'Update Country',
    'Update Course Mode',
    'Update Associated Keyword'
  ],
  COMMON_ADMIN_FIELD_PLACEHOLDER: [
    'Educational area',
    'Category',
    'ODFL focus',
    'Country',
    'Course Mode',
    'Tag'
  ],
  REDUCER_TYPES: {
    ADMIN_PANEL_COURSES_COUNT: 'ADMIN_PANEL_COURSES_COUNT',
    ADMIN_PANEL_USERS_COUNT: 'ADMIN_PANEL_USERS_COUNT',
    HANDLE_USER_MODE: 'HANDLE_USER_MODE',
    ADMIN_PANEL_USERS_SECTION: 'ADMIN_PANEL_USERS_SECTION',
    HANDLE_COURSE_MODE: 'HANDLE_COURSE_MODE',
    ADMIN_COURSE_TABLE_LOADING: 'ADMIN_COURSE_TABLE_LOADING',
    COURSE_CREATED_AT_THE_ADMIN_PANEL: 'COURSE_CREATED_AT_THE_ADMIN_PANEL',
    CURRENT_COURSE_DATA: 'CURRENT_COURSE_DATA',
    SELECTED_COURSE_FOR_EDIT: 'SELECTED_COURSE_FOR_EDIT',
    STORE_CURRENT_COURSE_ENTRIES: 'STORE_CURRENT_COURSE_ENTRIES',
    ADD_BUTTON_CLICKED: 'ADD_BUTTON_CLICKED',
    BULK_UPLOAD_CLICKED: 'BULK_UPLOAD_CLICKED',
    ADD_USER_BUTTON_CLICKED: 'ADD_USER_BUTTON_CLICKED',
    USER_CREATED_AT_THE_ADMIN_PANEL: 'USER_CREATED_AT_THE_ADMIN_PANEL',
    SET_CURRENT_USERS_TABLE_ADMIN_PANEL: 'SET_CURRENT_USERS_TABLE_ADMIN_PANEL',
    SET_CURRENT_SELECTED_USER_DATA: 'SET_CURRENT_SELECTED_USER_DATA',
    SET_COUNTRY_DATA: 'SET_COUNTRY_DATA',
    SET_EDUCATIONAL_AREA_DATA: 'SET_EDUCATIONAL_AREA_DATA',
    SET_INSTITUTION_DATA: 'SET_INSTITUTION_DATA',
    SET_ROLE_DATA: 'SET_ROLE_DATA',
    SET_ADMIN_LOADING_STATUS: 'SET_ADMIN_LOADING_STATUS',
    SET_USER_UPDATED_AT_THE_ADMIN_PANEL_STATUS:
      'SET_USER_UPDATED_AT_THE_ADMIN_PANEL_STATUS',
    SET_ODFL_FOCUS_DATA: 'SET_ODFL_FOCUS_DATA',
    SET_CATEGORIES_DATA: 'SET_CATEGORIES_DATE',
    SET_MODES_DATA: 'SET_MODES_DATA',
    SET_LEVELS_DATA: 'SET_LEVELS_DATA',
    SET_COMMON_INSTITUTION_DATA: 'SET_COMMON_INSTITUTION_DATA',
    SET_COMMON_COUNTRY_DATA: 'SET_COMMON_COUNTRY_DATA',
    SET_SITE_USER_PAGE_COURSE_LIST: 'SET_SITE_USER_PAGE_COURSE_LIST',
    SET_GLOBAL_FILTER_VALUES: 'SET_GLOBAL_FILTER_VALUES',
    SET_GLOBAL_TITLE_VALUES: 'SET_GLOBAL_TITLE_VALUES',
    INSTITUTION_ADD_BUTTON_CLICKED: 'INSTITUTION_ADD_BUTTON_CLICKED',
    INSTITUTION_ACTION_MODE: 'INSTITUTION_ACTION_MODE',
    EDIT_CURRENT_INSTITUTION: 'EDIT_CURRENT_INSTITUTION',
    INSTITUTIONS_COUNT: 'INSTITUTIONS_COUNT',
    INSTITUTIONS_DATA: 'INSTITUTIONS_DATA',
    SET_COURSE_TAGS: 'SET_COURSE_TAGS',
    SET_FAQ_DATA: 'SET_FAQ_DATA',
    SET_CATALOG_COURSE_PAGE: 'SET_CATALOG_COURSE_PAGE',
    CATALOG_COURSES_TOTAL_COUNT: 'CATALOG_COURSES_TOTAL_COUNT',
    SET_CERTIFICATION_DATA: 'SET_CERTIFICATION_DATA',
    SET_LOADING_STATUS: 'SET_LOADING_STATUS'
  },
  COURSE_DETAILS: {
    COURSE_DETAIL_CARD_BUTTON: 'I am Interested',
    COURSE_DETAIL_SUBTITLE: 'Description',
    COURSE_COST_STATUS: 'Free Course'
  },
  TABLE_CONFIRMATION_MODAL: {
    COURSES: {
      MODAL_TITLE: 'Delete Course',
      CONFIRMATION_MESSAGE: 'Are you sure you want to delete the course?'
    },
    USER: {
      MODAL_TITLE: 'Delete User',
      CONFIRMATION_MESSAGE: 'Are you sure you want to delete the user?'
    },
    INSTITUTION: {
      MODAL_TITLE: 'Delete Institution',
      CONFIRMATION_MESSAGE: 'Are you sure you want to delete the institution?'
    }
  },
  COURSE_INFO_TAB_PARAMETER: {
    BREADCRUMB_HOME: 'home'
  },
  COURSE_DESCRIPTION_FIELD_NAMES: {
    OPEN_COURSE: 'Open course',
    PAID_COURSE: 'Paid course',
    DURATION: 'Duration',
    COURSE_START_DATE: 'Course start-date',
    COURSE_END_DATE: 'Course end-date',
    COURSE_MODE: 'Course mode',
    COURSE_LEVEL: 'Course level',
    REGISTRANTS_OPENS_TO: 'Registrants open to',
    CERTIFICATION: 'Certification',
    FREE_PAID: 'Free/Paid',
    FACILITATOR_NAME: 'Facilitator name: ',
    INSTITUTION: 'Institution: '
  }
};
