import CONSTANTS from '../../../../constants/constants';

export const userUpdatedOnTheAdminPanel = (
  state: boolean,
  action: { payload: boolean; type: string }
) => {
  switch (action.type) {
    case CONSTANTS.REDUCER_TYPES.SET_USER_UPDATED_AT_THE_ADMIN_PANEL_STATUS:
      return action.payload;

    default:
      return state;
  }
};
