import React, { Suspense, useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import '../../../style/component/reactSelectCustom.scss';
import '../../../style/component/Header.scss';
import '../../../style/component/addCourse.scss';

import {
  Navbar,
  Container,
  Image,
  Nav,
  ListGroup,
  Dropdown,
  DropdownButton,
  Form,
  Button,
  InputGroup,
  Modal,
  Accordion
} from 'react-bootstrap';
import {
  IFAQData,
  IHeaderProps
} from '../../../interface/components.interface';
import CONSTANTS from '../../../constants/constants';
import useItToGetEducationalArea from '../../../hook/Common/useItToGetEducationalArea';
import { AppContext } from '../../../context/AppContext';
import useItToHandleSitUserPageFilter from '../../../hook/siteUser/useItToHandleSitUserPageFilter';
import useItToGetFAQ from '../../../hook/Common/useItToGetFAQ';
import Loader from './Loader';
import { Link } from 'react-router-dom';
const Header = (props: IHeaderProps) => {
  const [loading] = useItToGetEducationalArea();
  const [
    globalFilterData,
    handleValueChange,
    ,
    handleDropDownChange,
    handleSubmit
  ] = useItToHandleSitUserPageFilter();
  const { state } = useContext(AppContext);
  const [showSearchFields, setSearchFieldsVisibilty] = useState(false);
  const currentUserName = sessionStorage.getItem(
    CONSTANTS.SESSION_STORAGE.NAME_KEY
  );
  const edAreaOptions = state.commonEducationalAreas
    .map((ele: { id: number; name: string }) => ({
      label: ele.name,
      value: ele.id
    }))
    .filter((ele: { value: number }) => ele.value !== 1);

  const [isFocus, setIsFocus] = useState(false);
  const [isModalShow, setIsModalShow] = useState(false);
  const [loadingFaq] = useItToGetFAQ();

  const location = window.location.href.toString().split(window.location.host);
  const isAdmin = location.includes(CONSTANTS.ROUTE_PATHS.DASHBOARD);
  const isSite = location.includes(CONSTANTS.ROUTE_PATHS.ROOT);

  useEffect(() => {
    // This function for Safari UI issue.
    if (isSite) {
      const handleOutSideClick = (e: any) => {
        const headerFilter =
          document.getElementsByClassName('header-fliter')[0];
        setIsFocus(
          e.target.classList.contains('globalSearchFocus') ||
            headerFilter.getElementsByClassName(
              'col-select-dropdown__control--menu-is-open'
            ).length === 1
        );
      };
      window.addEventListener('click', handleOutSideClick);
    }
  });

  return (
    <Navbar bg="bg-white" expand="lg" className="sticky-top bg-white">
      <Container fluid className={isAdmin ? 'main' : 'site'}>
        <Navbar.Toggle
          aria-controls="col-collapse-navbar"
          className="col-navbar-brand"
        >
          <img src="/images/mobile-nav.svg" alt="Mobile Nav" />
        </Navbar.Toggle>
        <Navbar.Brand
          href="https://www.col.org/"
          target="_blank"
          className="brand-width"
        >
          <Image
            src="/images/Header.svg"
            className="header-logo col-logo-nav img-fluid ps-3"
            alt="Header"
          />
        </Navbar.Brand>
        <Navbar.Collapse id="col-collapse-navbar">
          {props.category === 'admin' ? (
            <Nav className="me-0 ms-auto">
              {!window.location.href.toString().includes('login') && (
                <div className="p-2">
                  <Link
                    to={CONSTANTS.CONFIG.HELP_DOCUMENT_PATH}
                    target="_blank"
                    download
                  >
                    <img src="/images/help.svg" alt="help" />
                  </Link>
                </div>
              )}
              {currentUserName ? (
                <Nav.Link className="login-link-col-header">
                  <div className="col-user-dropdown">
                    <DropdownButton title={currentUserName}>
                      <Dropdown.Item
                        onClick={() => window.open(CONSTANTS.ROUTE_PATHS.ROOT)}
                      >
                        Course Catalog
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          sessionStorage.clear();
                          window.location.pathname =
                            CONSTANTS.ROUTE_PATHS.LOGIN;
                        }}
                      >
                        Logout
                      </Dropdown.Item>
                    </DropdownButton>
                  </div>
                </Nav.Link>
              ) : null}
            </Nav>
          ) : (
            <div>
              <div className="d-flex">
                <div className="flex-grow-1">
                  <Nav className="navbar-nav list-inline">
                    <div className="mobile-search-bar mt-2 p-2">
                      <div className="mobile-ui-enable-search-fields">
                        <Button
                          onClick={() => {
                            setSearchFieldsVisibilty(true);
                            showSearchFields
                              ? setSearchFieldsVisibilty(false)
                              : setSearchFieldsVisibilty(true);
                          }}
                          className={
                            showSearchFields
                              ? 'mobile-search-style px-0'
                              : 'px-0'
                          }
                        >
                          Search Fields
                        </Button>
                        <Button
                          onClick={() => {
                            setIsModalShow(true);
                            setSearchFieldsVisibilty(false);
                          }}
                        >
                          {CONSTANTS.CATALOG.FAQ}
                        </Button>
                      </div>
                      {showSearchFields && (
                        <React.Fragment>
                          <div className="my-2">
                            <Select
                              placeholder="Educational Level"
                              onChange={(e) =>
                                handleDropDownChange(e, 'educationalAreaId')
                              }
                              name="educationalAreaId"
                              isLoading={loading}
                              options={[
                                { label: 'All', value: 0 },
                                ...edAreaOptions
                              ]}
                              className="col-select-dropdown"
                              classNamePrefix="col-select-dropdown"
                            />
                          </div>
                          <InputGroup className="my-2 search-field-mobile">
                            <Form.Control
                              type="text"
                              name="globalSearch"
                              placeholder="Search"
                              value={state.globalFilterValues.globalSearch}
                              onChange={(e) => handleValueChange(e)}
                              className="col-form-input search-icon-style searchBox"
                              onKeyDown={(e) =>
                                e.key === 'Enter' && handleSubmit()
                              }
                            />
                            <div className="search-icon">
                              <Button
                                className="input-group-text search-icon-focus"
                                onClick={() => handleSubmit()}
                              >
                                <Image
                                  src="/images/searchIcon.svg"
                                  alt="Search"
                                />
                              </Button>
                            </div>
                          </InputGroup>
                        </React.Fragment>
                      )}
                    </div>
                    <ListGroup className="navbar-nav top-margin list-inline mx-auto justify-content-center desktop-search-bar">
                      <Form className="container-fluid global-filter">
                        <div
                          className={
                            isFocus
                              ? 'input-group nav-search-top header-fliter set-col-input-focus'
                              : 'input-group nav-search-top header-fliter'
                          }
                        >
                          <Select
                            placeholder="Educational Level"
                            onChange={(e) =>
                              handleDropDownChange(e, 'educationalAreaId')
                            }
                            name="educationalAreaId"
                            isLoading={loading}
                            value={globalFilterData}
                            options={[
                              { label: 'All', value: 0 },
                              ...edAreaOptions
                            ]}
                            className="col-select-dropdown"
                            classNamePrefix="col-select-dropdown"
                          />
                          <Form.Control
                            type="text"
                            name="globalSearch"
                            placeholder="Search"
                            value={state.globalFilterValues.globalSearch}
                            onChange={(e) => handleValueChange(e)}
                            onKeyDown={(e) =>
                              e.key === 'Enter' && handleSubmit()
                            }
                            className="col-form-input search-icon-style searchBox globalSearchFocus"
                          />
                          <div className="search-icon">
                            <Button
                              className="input-group-text search-icon-focus globalSearchFocus"
                              onClick={() => handleSubmit()}
                            >
                              <Image
                                src="/images/searchIcon.svg"
                                alt="Search"
                                className="globalSearchFocus"
                              />
                            </Button>
                          </div>
                        </div>
                      </Form>
                    </ListGroup>
                  </Nav>
                </div>
                <div className="faq-style">
                  <Button onClick={() => setIsModalShow(true)}>
                    <Image src="/images/help.svg" alt="FAQ" />
                  </Button>
                </div>
              </div>
            </div>
          )}
        </Navbar.Collapse>
      </Container>

      <Modal
        show={isModalShow}
        onHide={() => setIsModalShow(false)}
        className="faq-modal"
        fullscreen
      >
        <Modal.Header closeButton>{CONSTANTS.CATALOG.FAQ}</Modal.Header>
        <Modal.Body>
          <Suspense fallback={<Loader />}>
            {loadingFaq ? (
              <Loader />
            ) : (
              <Accordion>
                {state.faq.map((faq: IFAQData, index: number) => (
                  <Accordion.Item
                    eventKey={index.toString()}
                    key={index}
                    className="my-3"
                  >
                    <Accordion.Header>
                      <span className="question-style">{faq.question}</span>
                    </Accordion.Header>
                    <Accordion.Body>{faq.answer}</Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            )}
          </Suspense>
        </Modal.Body>
      </Modal>
    </Navbar>
  );
};

export default Header;
