import { ILoginProps } from '../../interface/components.interface';
import {
  IRegisterPayload,
  IUserServicePayload
} from '../../interface/services.interface';
import CONSTANTS from '../../constants/constants';
import axios from '../config';

class UserService implements IUserServicePayload {
  async loginUserService(data: ILoginProps) {
    try {
      return await axios({
        url: '/user/login',
        method: 'POST',
        data
      });
    } catch (err) {
      return (err as any).response;
    }
  }

  async securityCode(email: string) {
    try {
      return await axios({
        url: '/securityCode',
        method: 'POST',
        data: { email: email }
      });
    } catch (err: any) {
      return err.response;
    }
  }

  async verfiySecurityCode(code: string) {
    try {
      return await axios({
        url: `/verifySecurityCode/${code}`,
        method: 'GET'
      });
    } catch (err: any) {
      return err.response;
    }
  }

  async forgotPassword(email: string, password: string, codeId: number) {
    const userData = {
      email: email,
      password: password,
      codeId: codeId
    };
    try {
      return await axios({
        url: '/forgotPassword',
        method: 'POST',
        data: userData
      });
    } catch (err: any) {
      return err.response;
    }
  }

  async deleteUser(currentUserId: number, userIdToDelete: number) {
    try {
      return await axios({
        method: 'DELETE',
        url: `/user/${currentUserId}/removeUser`,
        data: { id: userIdToDelete }
      });
    } catch (err) {
      return err;
    }
  }
  async registerUserService(userId: number, data: IRegisterPayload) {
    try {
      return (
        await axios({
          url: `/user/${userId}/register`,
          method: 'POST',
          data
        })
      ).status;
    } catch (err) {
      return (err as any).response.status;
    }
  }

  async resetIntialLoginPassword(
    email: string,
    oldPassword: string,
    newPassword: string
  ) {
    try {
      return await axios({
        url: `/user/${Number(
          sessionStorage.getItem(CONSTANTS.SESSION_STORAGE.USER_ID_KEY)
        )}/resetPasswordOnIntialLogin`,
        method: 'POST',
        data: { email, oldPassword, newPassword }
      });
    } catch (err) {
      return err;
    }
  }

  async updateUser(
    userId: number,
    currentRecordId: number,
    payload: IRegisterPayload
  ) {
    try {
      return await axios({
        method: 'PUT',
        url: `/user/${userId}/updateUser`,
        data: {
          id: currentRecordId,
          data: payload
        }
      });
    } catch (err) {
      return err;
    }
  }

  async fetchPaginatedListOfUsers(
    userId: number,
    sections: number,
    limit: number
  ) {
    try {
      return await axios({
        url: `/user/${userId}/getUser?sections=${sections}&limit=${limit}`,
        method: 'GET'
      });
    } catch (err) {
      return err;
    }
  }
}

export default new UserService();
