import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import ErrorPage from '../page/Error';
import { IAppRouteProps } from './routes.interface';
import '../style/_theme.scss';
import Header from '../component/admin/common/Header';
import CONSTANTS from '../constants/constants';
import Footer from '../component/admin/common/Footer';
/**
 * This file is responsible for generating corresponding routes as per the config from Routes.tsx
 * Don't touch this file until you need to add a static component to all routes.
 */

const AppRoutes = (props: IAppRouteProps) => {
  const currentUserId = sessionStorage.getItem(
    CONSTANTS.SESSION_STORAGE.USER_ID_KEY
  );
  const isFirstLogin = sessionStorage.getItem(
    CONSTANTS.SESSION_STORAGE.FIRST_LOGIN_STATUS_KEY
  );

  return (
    <BrowserRouter>
      <Routes>
        {props.routeConfig.map((ele, index) => (
          <Route
            path={ele.path}
            element={
              !ele.isLoginRequired ? (
                ele.path.includes('login') &&
                currentUserId &&
                isFirstLogin === 'false' ? (
                  <Navigate to={CONSTANTS.ROUTE_PATHS.DASHBOARD} />
                ) : (
                  <React.Fragment>
                    <Header category={ele.category} />
                    <div
                      className={
                        !ele.path.includes('login') ? 'col-site-bg' : undefined
                      }
                    >
                      <ele.component />
                    </div>
                    <Footer />
                  </React.Fragment>
                )
              ) : currentUserId !== undefined &&
                currentUserId !== null &&
                isFirstLogin === 'false' ? (
                <React.Fragment>
                  <Header category={ele.category} />
                  <div
                    className={currentUserId ? 'col-admin-bg' : 'col-site-bg'}
                  >
                    <ele.component />
                  </div>
                  <Footer />
                </React.Fragment>
              ) : (
                <Navigate to={CONSTANTS.ROUTE_PATHS.LOGIN} />
              )
            }
            key={index}
          />
        ))}
        {/* For 404 Routes */}
        <Route
          path="*"
          element={
            <ErrorPage
              title={CONSTANTS.STATUS_CODES.CODE_404.toString()}
              message={CONSTANTS.ERROR_MESSAGE.COMMON.NOT_FOUND}
            />
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
