import CONSTANTS from '../../../constants/constants';

export const catalogCoursesCount = (
  state: number,
  action: { type: string; payload: number }
) => {
  switch (action.type) {
    case CONSTANTS.REDUCER_TYPES.CATALOG_COURSES_TOTAL_COUNT:
      return action.payload;
    default:
      return state;
  }
};
