import axios from '../config';

export default async (): Promise<unknown> => {
  try {
    return await axios({
      url: '/educationalArea/getAll',
      method: 'GET'
    });
  } catch (err) {
    return err;
  }
};
