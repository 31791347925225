import React from 'react';
import '../../../style/component/Loader.scss';

const Loader = () => {
  return (
    <div className="d-flex align-items-center justify-content-center min-vh-100">
      <div className="lds-dual-ring"></div>
    </div>
  );
};

export default Loader;
