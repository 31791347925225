import CONSTANTS from '../../../../constants/constants';
export const institutionsCount = (
  state: number,
  action: { type: string; payload: number }
) => {
  switch (action.type) {
    case CONSTANTS.REDUCER_TYPES.INSTITUTIONS_COUNT:
      return action.payload;
    default:
      return state;
  }
};
