import CONSTANTS from '../../../../constants/constants';
import { IEducationalAreaState } from '../../../../interface/components.interface';

export const getEducationalAreaReducer = (
  state: IEducationalAreaState[],
  action: { type: string; payload: IEducationalAreaState[] }
) => {
  switch (action.type) {
    case CONSTANTS.REDUCER_TYPES.SET_EDUCATIONAL_AREA_DATA:
      return action.payload;

    default:
      return state;
  }
};
