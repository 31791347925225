import CONSTANTS from '../../../constants/constants';
import { IGlobalFilterValues } from '../../../interface/components.interface';

const selectedGlobalValues = (
  state: IGlobalFilterValues,
  action: { type: string; payload: IGlobalFilterValues }
) => {
  switch (action.type) {
    case CONSTANTS.REDUCER_TYPES.SET_GLOBAL_FILTER_VALUES:
      return { ...state, ...action.payload };

    default:
      return state;
  }
};

export default selectedGlobalValues;
