import { useContext, useEffect, useState } from 'react';
import fetchFAQ from '../../service/Common/fetchFAQ';
import CONSTANTS from '../../constants/constants';
import { AppContext } from '../../context/AppContext';
import { AxiosResponse } from 'axios';

export default (): [boolean, () => void] => {
  const [loading, setLoading] = useState(true);
  const { dispatch } = useContext(AppContext);
  const [trigger, setTrigger] = useState(false);
  const handleTrigger = () => setTrigger((prev) => !prev);

  useEffect(() => {
    setLoading(true);
    fetchFAQ()
      .then((response: unknown) => {
        dispatch({
          type: CONSTANTS.REDUCER_TYPES.SET_FAQ_DATA,
          payload: (response as AxiosResponse).data.info
        });
        setLoading(false);
      })
      .catch((response) => {
        console.log(response.data.info);
        setLoading(false);
      });
  }, [trigger]);

  return [loading, handleTrigger];
};
