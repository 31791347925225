import React, { useState } from 'react';
import { Form, Button, Container, Card, Col } from 'react-bootstrap';
import '../style/component/Login.scss';
import useItToLoginUser from '../hook/user/useItToLoginUser';
import CONSTANTS from '../constants/constants';
import useItToResetIntialPassword from '../hook/user/useItToResetIntialPassword';
import { Link } from 'react-router-dom';

const Login = () => {
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [showNewPassword, setShowNewPasswordVisibility] = useState(false);
  const [showConfirmPassword, setConfirmPasswordVisibility] = useState(false);
  const [
    formValues,
    isFirstLogin,
    formErrors,
    handleChange,
    handleForgotPassword,
    isForgotPassword,
    isVerified,
    isCodeStatus,
    handleSetForgotPassword,
    handleSubmit
  ] = useItToLoginUser();
  const [passwords, passwordErrors, handlePasswordChange, handleResetPassword] =
    useItToResetIntialPassword();

  return (
    <div className="login-page d-flex align-items-center">
      <Container fluid>
        <Card className="border-style p-4">
          <Card.Body>
            {isForgotPassword ? (
              <div className="forgotPassword">
                <h6 className="login-head pb-2 col-form-title">
                  Forgot Password
                </h6>
                <Form.Group className="col-label-focus mb-1">
                  <Form.Label className="col-form-label">
                    Security Code
                  </Form.Label>
                  <div className="security-code-style">
                    <Form.Control
                      type="text"
                      name="securityCode"
                      maxLength={CONSTANTS.FIELD_LIMIT.USER.SECURITY_CODE}
                      value={formValues.securityCode}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleChange(e)
                      }
                      className={
                        isCodeStatus === 1 &&
                        formValues.securityCode?.length ===
                          CONSTANTS.CONFIG.SECURITY_CODE_LIMIT
                          ? 'success col-form-input'
                          : 'col-form-input'
                      }
                    />
                    {isCodeStatus === 0 &&
                      formValues.securityCode?.length ===
                        CONSTANTS.CONFIG.SECURITY_CODE_LIMIT && (
                        <span className="spinner-border"></span>
                      )}
                  </div>
                </Form.Group>
                <div className="d-flex justify-content-between mb-3">
                  <div>
                    {formErrors.securityCode && (
                      <span className="text-danger">
                        {formErrors.securityCode}
                      </span>
                    )}
                  </div>
                  <div>
                    <Link to="" onClick={() => handleForgotPassword()}>
                      <span className="fw-bold login-head text-dark forget-password">
                        Resend Code
                      </span>
                    </Link>
                  </div>
                </div>

                <Form.Group
                  className="mb-3 col-label-focus"
                  controlId="formBasicNewPassword"
                >
                  <Form.Label>New Password</Form.Label>
                  <div className="image-style mb-3">
                    <Form.Control
                      disabled={isVerified}
                      className={
                        isVerified
                          ? 'cursor-not-allowed col-form-input'
                          : 'col-form-input'
                      }
                      type={showNewPassword ? 'text' : 'password'}
                      name="password"
                      value={formValues.password}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleChange(e)
                      }
                      maxLength={CONSTANTS.FIELD_LIMIT.USER.PASSWORD}
                    />
                    <Button
                      disabled={isVerified}
                      className={
                        isVerified ? 'cursor-not-allowed eye-icon' : 'eye-icon'
                      }
                      onClick={() =>
                        setShowNewPasswordVisibility((prev) => !prev)
                      }
                    >
                      <img
                        alt="password-icon"
                        src={
                          showNewPassword
                            ? '/images/hide_password.svg'
                            : '/images/show_password.svg'
                        }
                      />
                    </Button>
                    {formErrors.password && (
                      <span className="text-danger">{formErrors.password}</span>
                    )}
                  </div>
                </Form.Group>
                <Form.Group
                  className="mb-3 col-label-focus"
                  controlId="formBasicConfirmPassword"
                >
                  <Form.Label>Confirm New Password</Form.Label>
                  <div className="image-style mb-3">
                    <Form.Control
                      disabled={isVerified}
                      className={
                        isVerified
                          ? 'cursor-not-allowed col-form-input'
                          : 'col-form-input'
                      }
                      type={showConfirmPassword ? 'text' : 'password'}
                      name="confirmPassword"
                      value={formValues.confirmPassword}
                      onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                        handleChange(evt)
                      }
                      maxLength={CONSTANTS.FIELD_LIMIT.USER.PASSWORD}
                    />
                    <Button
                      disabled={isVerified}
                      className="eye-icon"
                      onClick={() =>
                        setConfirmPasswordVisibility((prev) => !prev)
                      }
                    >
                      <img
                        alt="password-icon"
                        src={
                          showConfirmPassword
                            ? '/images/hide_password.svg'
                            : '/images/show_password.svg'
                        }
                      />
                    </Button>
                    {formErrors.confirmPassword && (
                      <span className="text-danger">
                        {formErrors.confirmPassword}
                      </span>
                    )}
                  </div>
                </Form.Group>
                <Form.Group>
                  <Col className="text-center mt-5">
                    <Button
                      disabled={isVerified}
                      className={
                        isVerified
                          ? 'cursor-not-allowed col-submit-btn'
                          : 'col-submit-btn'
                      }
                      onClick={() => handleSetForgotPassword()}
                    >
                      Update Password
                    </Button>
                  </Col>
                </Form.Group>
              </div>
            ) : (
              <React.Fragment>
                <h6 className="login-head pb-2 col-form-title">Login</h6>
                <Form.Group
                  className="mb-3 col-label-focus"
                  controlId="formBasicEmail"
                >
                  <Form.Label>Email ID</Form.Label>
                  <Form.Control
                    className="col-form-input"
                    type="email"
                    name="email"
                    disabled={isFirstLogin}
                    maxLength={CONSTANTS.FIELD_LIMIT.USER.EMAIL}
                    value={formValues.email}
                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                      handleChange(evt)
                    }
                  />
                  {formErrors.email && (
                    <span className="text-danger">{formErrors.email}</span>
                  )}
                </Form.Group>
                <Form.Group
                  className="mb-3 col-label-focus"
                  controlId="formBasicPassword"
                >
                  <Form.Label>
                    {isFirstLogin ? 'Old Password' : 'Password'}
                  </Form.Label>
                  <div className="image-style mb-3">
                    <Form.Control
                      className="col-form-input"
                      type={isRevealPwd ? 'text' : 'password'}
                      name="password"
                      value={formValues.password}
                      onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                        handleChange(evt)
                      }
                      maxLength={CONSTANTS.FIELD_LIMIT.USER.PASSWORD}
                    />
                    <Button
                      className="eye-icon"
                      onClick={() => setIsRevealPwd((prev) => !prev)}
                    >
                      <img
                        alt="password-icon"
                        src={
                          isRevealPwd
                            ? '/images/hide_password.svg'
                            : '/images/show_password.svg'
                        }
                      />
                    </Button>
                    <span className="text-danger">
                      {formErrors.password ||
                        (passwordErrors.oldPassword && (
                          <span className="text-danger">
                            {isFirstLogin
                              ? passwordErrors.oldPassword
                              : formErrors.password}
                          </span>
                        ))}
                    </span>
                  </div>
                </Form.Group>
                {isFirstLogin && (
                  <React.Fragment>
                    <Form.Group
                      className="mb-3 col-label-focus"
                      controlId="formBasicNewPassword"
                    >
                      <Form.Label>New Password</Form.Label>
                      <div className="image-style mb-3">
                        <Form.Control
                          className="col-form-input"
                          type={showNewPassword ? 'text' : 'password'}
                          name="newPassword"
                          value={passwords.newPassword}
                          onChange={(
                            evt: React.ChangeEvent<HTMLInputElement>
                          ) => handlePasswordChange(evt)}
                          maxLength={CONSTANTS.FIELD_LIMIT.USER.PASSWORD}
                        />
                        <Button
                          className="eye-icon"
                          onClick={() =>
                            setShowNewPasswordVisibility((prev) => !prev)
                          }
                        >
                          <img
                            alt="password-icon"
                            src={
                              showNewPassword
                                ? '/images/hide_password.svg'
                                : '/images/show_password.svg'
                            }
                          />
                        </Button>
                        {passwordErrors.newPassword && (
                          <span className="text-danger">
                            {passwordErrors.newPassword}
                          </span>
                        )}
                      </div>
                    </Form.Group>
                    <Form.Group
                      className="mb-3 col-label-focus"
                      controlId="formBasicConfirmPassword"
                    >
                      <Form.Label>Confirm New Password</Form.Label>
                      <div className="image-style mb-3">
                        <Form.Control
                          className="col-form-input"
                          type={showConfirmPassword ? 'text' : 'password'}
                          name="confirmPassword"
                          value={passwords.confirmPassword}
                          onChange={(
                            evt: React.ChangeEvent<HTMLInputElement>
                          ) => handlePasswordChange(evt)}
                          maxLength={CONSTANTS.FIELD_LIMIT.USER.PASSWORD}
                        />
                        <Button
                          className="eye-icon"
                          onClick={() =>
                            setConfirmPasswordVisibility((prev) => !prev)
                          }
                        >
                          <img
                            alt="password-icon"
                            src={
                              showConfirmPassword
                                ? '/images/hide_password.svg'
                                : '/images/show_password.svg'
                            }
                          />
                        </Button>
                        {passwordErrors.confirmPassword && (
                          <span className="text-danger">
                            {passwordErrors.confirmPassword}
                          </span>
                        )}
                      </div>
                    </Form.Group>
                  </React.Fragment>
                )}
                <Link to="" onClick={() => handleForgotPassword()}>
                  <p className="fw-bold text-dark forget-password">
                    <u>Forgot Password?</u>
                  </p>
                </Link>

                <div className="text-center">
                  <Button
                    type="submit"
                    className="fw-bold login-button"
                    onClick={(e: any) => {
                      isFirstLogin
                        ? handleResetPassword(
                            e,
                            formValues.password,
                            formValues.email
                          )
                        : handleSubmit(e);
                    }}
                  >
                    {isFirstLogin ? 'Reset Password & Login' : 'Login'}
                  </Button>
                </div>
              </React.Fragment>
            )}
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
};
export default Login;
