import { ICurrentCourseForSelected } from '../../../../interface/reducer.interface';
import CONSTANTS from '../../../../constants/constants';

export const currentCourseForSelected = (
  state: boolean,
  action: { type: string; payload: ICurrentCourseForSelected[] }
) => {
  switch (action.type) {
    case CONSTANTS.REDUCER_TYPES.SELECTED_COURSE_FOR_EDIT:
      return action.payload;
    default:
      return state;
  }
};
