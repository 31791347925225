import CONSTANTS from '../../../../constants/constants';
import { IInstitutionColumnData } from '../../../../interface/reducer.interface';

export const institutionsData = (
  state: any,
  action: { type: string; payload: IInstitutionColumnData[] }
) => {
  switch (action.type) {
    case CONSTANTS.REDUCER_TYPES.INSTITUTIONS_DATA:
      return action.payload;
    default:
      return state;
  }
};
