import CONSTANTS from '../../../constants/constants';
import { IHomeCourseTitle } from '../../../interface/components.interface';

const homeCourseDatas = (
  state: IHomeCourseTitle,
  action: { type: string; payload: IHomeCourseTitle }
) => {
  switch (action.type) {
    case CONSTANTS.REDUCER_TYPES.SET_GLOBAL_TITLE_VALUES:
      return { ...state, ...action.payload };

    default:
      return state;
  }
};

export default homeCourseDatas;
