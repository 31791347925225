import { IUsersColumnData } from '../../../../interface/components.interface';
import CONSTANTS from '../../../../constants/constants';

export const usersTableCurrentDataReducer = (
  state: IUsersColumnData[],
  action: { type: string; payload: IUsersColumnData[] }
) => {
  switch (action.type) {
    case CONSTANTS.REDUCER_TYPES.SET_CURRENT_USERS_TABLE_ADMIN_PANEL:
      return action.payload;

    default:
      return state;
  }
};
