import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import getCourseById from '../service/Courses/getCourseById';
import '../style/component/CourseDetails.scss';
import CONSTANTS from '../constants/constants';
import Loader from '../component/admin/common/Loader';
import { Button, Container, Row, Col, Card, Image } from 'react-bootstrap';
import ErrorPage from './Error';
import { useNavigate } from 'react-router';
import useItToHandleSiteUserListCourses from '../hook/siteUser/useItToHandleSiteUserListCourses';
import useItToHandleDescriptionDetails from '../hook/Common/useItToHandleDescriptionDetails';
import { EmailShareButton } from 'react-share';

const CouseDescription = () => {
  const [loading, setLoading] = useState(true);
  const [catalogCourseList, setCatalogueCourseList] = useState({} as any);
  const { id } = useParams();
  const history = useNavigate();

  useEffect(() => {
    setLoading(true);
    getCourseById(id).then((response: any) => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      if (response.status === CONSTANTS.STATUS_CODES.CODE_200) {
        setCatalogueCourseList(response.data.info);
        setLoading(false);
        courseCardDatas(response.data.info);
      }
    });
  }, [id]);

  const [courseCardDatas, modifiedCardDatas] =
    useItToHandleDescriptionDetails();

  const [handleSiteUserCourses] = useItToHandleSiteUserListCourses();

  const patternDomain = new RegExp(
    '^((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  );

  return (
    <React.Fragment>
      {loading ? (
        <Loader />
      ) : Object.keys(catalogCourseList).length ? (
        <React.Fragment>
          <Container className="ps-3 pe-3" fluid>
            <div className="d-flex">
              <Button
                className="home-back-button"
                onClick={() => {
                  history(CONSTANTS.ROUTE_PATHS.ROOT);
                  handleSiteUserCourses();
                }}
              >
                <img src="/images/back_button.svg" alt="Back Button" />
              </Button>
            </div>
            <Row className="mb-3">
              <Col sm={12} md={6} lg={8}>
                <div className="d-flex justify-content-between">
                  <div className="description-heading mb-3">
                    <h3 className="fw-bold">{catalogCourseList.title}</h3>
                  </div>
                  <EmailShareButton
                    body={
                      'Hi, You have got a course recommendation, Please click on the following link to check it out '
                    }
                    url={
                      patternDomain.test(catalogCourseList?.url)
                        ? `http://${catalogCourseList?.url}`
                        : catalogCourseList?.url
                    }
                  >
                    <Image src="/images/share_mail.svg" />
                  </EmailShareButton>
                </div>
                <p className="fw-bold text-title">
                  {CONSTANTS.COURSE_DETAILS.COURSE_DETAIL_SUBTITLE}
                </p>
                <Row>
                  <p className="text-sy">
                    {catalogCourseList.short_description}
                  </p>
                </Row>
                <hr className="course-detailed-page-divider mb-4" />
                <Row>
                  <p className="text-sy">
                    {catalogCourseList.long_description}
                  </p>
                </Row>
              </Col>
              <Col sm={12} md={6} lg={4}>
                <Card className="description-card-style">
                  <Card.Img
                    variant="top"
                    src={catalogCourseList.thumbnail_url}
                    className="course-img-thumbnail"
                    onError={(e: any) => {
                      e.target.src = CONSTANTS.CONFIG.DEFAULT_IMAGE_PATH;
                    }}
                  />
                  <Card.Body className="detailed-card-body">
                    {catalogCourseList.institution && (
                      <Card.Text className="detailed-card-text-title">
                        <b>
                          {CONSTANTS.COURSE_DESCRIPTION_FIELD_NAMES.INSTITUTION}
                        </b>
                        {catalogCourseList.institution.campus}
                      </Card.Text>
                    )}
                    {Object.keys(modifiedCardDatas).map(
                      (ele: string, index: number) => (
                        <Card.Text className="detailed-card-text" key={index}>
                          {ele && <b>{ele}:</b>} {modifiedCardDatas[ele]}
                        </Card.Text>
                      )
                    )}
                    <Card.Text className="detailed-card-text">
                      <div className="search-icon">
                        {catalogCourseList.facilitator_name && (
                          <>
                            <b>
                              {
                                CONSTANTS.COURSE_DESCRIPTION_FIELD_NAMES
                                  .FACILITATOR_NAME
                              }
                            </b>
                            {catalogCourseList.facilitator_name}
                          </>
                        )}
                        {catalogCourseList.facilitator_mail && (
                          <a
                            href={
                              'mailto:' + catalogCourseList.facilitator_mail
                            }
                          >
                            <Image
                              src="/images/mail.svg"
                              alt="mailto"
                              className="mail-icon"
                            />
                          </a>
                        )}
                      </div>
                    </Card.Text>
                    <Button
                      className="card-button description-submit"
                      href={
                        patternDomain.test(catalogCourseList?.url)
                          ? `http://${catalogCourseList?.url}`
                          : catalogCourseList?.url
                      }
                      target="_blank"
                    >
                      {CONSTANTS.COURSE_DETAILS.COURSE_DETAIL_CARD_BUTTON}
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </React.Fragment>
      ) : (
        <ErrorPage title="No Records Found" />
      )}
    </React.Fragment>
  );
};

export default CouseDescription;
