import CONSTANTS from '../../../../constants/constants';
import { ICourseTags } from '../../../../interface/components.interface';

export const setCourseTagsDataReducer = (
  state: ICourseTags[],
  action: { type: string; payload: ICourseTags[] }
) => {
  switch (action.type) {
    case CONSTANTS.REDUCER_TYPES.SET_COURSE_TAGS:
      return action.payload;

    default:
      return state;
  }
};
