import moment from 'moment';
import { useState } from 'react';
import CONSTANTS from '../../constants/constants';

const useItToHandleDescriptionDetails = (): [any, any] => {
  const [modifiedCardDatas, setModifyData] = useState({} as any);
  const fetchDuration = (value: number) => {
    return CONSTANTS.CONFIG.DURATION_DROPDOWN.filter(
      (opt) => opt.value === value
    )[0]?.label;
  };

  const certificationName = (iscertified: number) => {
    switch (iscertified) {
      case 1:
        return CONSTANTS.CATALOG.CERTIFICATE_AVAILABLE_CREDIT_ELIGIBLE;
      case 2:
        return CONSTANTS.CATALOG.CERTIFICATE_AVAILABLE_NONCREDIT_ELIGIBLE;
      case 3:
        return CONSTANTS.CATALOG.CERTIFICATE_NOT_AVAILABLE;
      default:
        break;
    }
  };
  const getDate = (courseDate: Date) => {
    const startDate = new Date(courseDate);
    const courseStartDate = moment(startDate).format('DD-MM-YYYY');
    return courseStartDate;
  };

  const courseCardDatas = (courseDetails: any) => {
    const finalCardDetails = {} as any;
    finalCardDetails[
      CONSTANTS.COURSE_DESCRIPTION_FIELD_NAMES.REGISTRANTS_OPENS_TO
    ] = courseDetails.country.name;
    finalCardDetails[CONSTANTS.COURSE_DESCRIPTION_FIELD_NAMES.DURATION] =
      courseDetails.durationUnit + '' + fetchDuration(courseDetails.duration);
    if (courseDetails.course_modes) {
      finalCardDetails[CONSTANTS.COURSE_DESCRIPTION_FIELD_NAMES.COURSE_MODE] =
        courseDetails.course_modes.name;
    }
    if (courseDetails.level) {
      finalCardDetails[CONSTANTS.COURSE_DESCRIPTION_FIELD_NAMES.COURSE_LEVEL] =
        courseDetails.level.name;
    }
    finalCardDetails[
      CONSTANTS.COURSE_DESCRIPTION_FIELD_NAMES.COURSE_START_DATE
    ] = getDate(courseDetails.start_date);
    if (!courseDetails.end_date_not_applicable) {
      finalCardDetails[
        CONSTANTS.COURSE_DESCRIPTION_FIELD_NAMES.COURSE_END_DATE
      ] = getDate(courseDetails.end_date);
    } else {
      finalCardDetails[''] =
        CONSTANTS.COURSE_DESCRIPTION_FIELD_NAMES.OPEN_COURSE;
    }
    finalCardDetails[CONSTANTS.COURSE_DESCRIPTION_FIELD_NAMES.CERTIFICATION] =
      certificationName(courseDetails.certification_id);
    if (courseDetails.is_paid) {
      finalCardDetails[CONSTANTS.COURSE_DESCRIPTION_FIELD_NAMES.PAID_COURSE] =
        courseDetails.paid_cost + ' USD';
    } else {
      finalCardDetails[CONSTANTS.COURSE_DESCRIPTION_FIELD_NAMES.FREE_PAID] =
        CONSTANTS.COURSE_DETAILS.COURSE_COST_STATUS;
    }
    setModifyData(finalCardDetails);
  };

  return [courseCardDatas, modifiedCardDatas];
};

export default useItToHandleDescriptionDetails;
