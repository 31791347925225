import CONSTANTS from '../../../../constants/constants';

export const userCreatedAtTheAdminPanel = (
  state: boolean,
  action: { payload: boolean; type: string }
) => {
  switch (action.type) {
    case CONSTANTS.REDUCER_TYPES.USER_CREATED_AT_THE_ADMIN_PANEL:
      return action.payload;

    default:
      return state;
  }
};
