import { IUserMode } from '../../../../interface/reducer.interface';
import CONSTANTS from '../../../../constants/constants';

export const adminPanelCourseMode = (
  state: IUserMode,
  action: { type: string; payload: IUserMode }
) => {
  switch (action.type) {
    case CONSTANTS.REDUCER_TYPES.HANDLE_USER_MODE:
      return action.payload;
    default:
      return state;
  }
};
