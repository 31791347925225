import CONSTANTS from '../../../constants/constants';

export default (state: number, action: { type: string; payload: number }) => {
  switch (action.type) {
    case CONSTANTS.REDUCER_TYPES.SET_CATALOG_COURSE_PAGE:
      return action.payload;

    default:
      return state;
  }
};
